import { FieldGuesser, ListGuesser, ShowGuesser } from '@api-platform/admin';
import {
  ReferenceField, TextField, NumberField, BooleanField,
} from 'react-admin';
import React from 'react';
import { getProp } from '../helpers';
import { CustomPagination, JsonField } from '../fields/resources';

const ReportCount = (props) => {
  const { record, source } = props;

  let style = {};

  if (record && getProp(record, source) >= 3) {
    style = { color: 'red', fontWeight: 'bold' };
  }

  return <NumberField style={style} {...props} />;
};

export const ReportsList = (props) => (
  <ListGuesser {...props} sort={{ field: 'createdAt', order: 'DESC' }} pagination={<CustomPagination />} perPage={25}>
    <ReportCount source="status.reportedCount" label="Reports count" />
    <ReferenceField source="reportedPost" reference="posts" link="show">
      <TextField source="title" />
    </ReferenceField>
    <ReferenceField source="reportedPost" reference="posts" link={false} label="Post state">
      <TextField source="state" />
    </ReferenceField>
    <ReferenceField source="reportedUser" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField source="reportedUser" reference="users" link={false} label="Disabled">
      <BooleanField source="disabled" />
    </ReferenceField>
    <FieldGuesser source="reason" addLabel />
    <FieldGuesser source="creatorComment" addLabel />
    <FieldGuesser source="reportedPostTargetTransition" label="Admin action" />
    <FieldGuesser source="createdAt" addLabel showTime />
    <ReferenceField source="createdBy" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
  </ListGuesser>
);

export const ReportShow = (props) => (
  <ShowGuesser {...props}>
    <ReferenceField source="reportedPost" reference="posts" link="show">
      <TextField source="title" />
    </ReferenceField>
    <ReferenceField source="reportedUser" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="reason" addLabel />
    <FieldGuesser source="creatorComment" addLabel />
    <FieldGuesser source="reportedPostTargetTransition" label="Admin action" />
    <FieldGuesser source="createdAt" addLabel showTime />
    <ReferenceField source="createdBy" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <JsonField source="status" addLabel />
  </ShowGuesser>
);
