import {
  CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser,
} from '@api-platform/admin';
import { Filter, TextInput } from 'react-admin';
import React from 'react';
import { CustomPagination, LatLngInput } from '../fields/resources';

const LocalitiesFilter = (props) => (
  <Filter {...props}>
    <TextInput source="postalCode" alwaysOn />
    <TextInput source="cityCode" alwaysOn />
    <TextInput source="name" alwaysOn />
  </Filter>
);

export const LocalitiesList = (props) => (
  <ListGuesser {...props} sort={{ field: 'cityCode', order: 'ASC' }} filters={<LocalitiesFilter />} pagination={<CustomPagination />}>
    <FieldGuesser source="country" addLabel />
    <FieldGuesser source="postalCode" addLabel />
    <FieldGuesser source="cityCode" addLabel />
    <FieldGuesser source="name" addLabel />
  </ListGuesser>
);

const generateInputs = () => [
  <InputGuesser source="country" key="country" helperText="Example: FR, EN" />,
  <InputGuesser source="postalCode" key="postalCode" />,
  <InputGuesser source="cityCode" key="cityCode" />,
  <InputGuesser source="name" key="name" />,
  <LatLngInput source="location" />,
];

export const LocalityCreate = (props) => (
  <CreateGuesser {...props} redirect="list">
    {generateInputs().map((Comp) => Comp)}
  </CreateGuesser>
);

export const LocalityEdit = (props) => (
  <EditGuesser {...props} mutationMode="pessimistic" redirect="list">
    {generateInputs().map((Comp) => Comp)}
  </EditGuesser>
);
