import {
  Button, fetchEnd, fetchStart, SaveButton, Toolbar, useNotify, useRefresh,
} from 'react-admin';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactJson from 'react-json-view';
import { apiFetch, apiFetchJson } from '../apiFetch';

export const ApplyTransitionButton = ({
  record, transition, label, alertMessage,
}) => {
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    let reason;

    // eslint-disable-next-line no-restricted-globals
    if (transition !== 'delete' && !confirm(alertMessage || `Are you sure you want to ${transition} this item ?`)) {
      return;
    }

    if (transition === 'delete') {
      reason = prompt('Why are you deleting this item ?');
      if (!reason) {
        notify('You must provide a reason', { type: 'warning' });
        return;
      }
    }

    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    apiFetch(`${record.id}/state`, { method: 'PUT', body: { transition, reason } })
      .then(() => {
        notify('Transition applied with success', { type: 'info' });
        refresh();
      })
      .catch((e) => {
        notify(`Error when applying the transition: ${e.message}`, { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <Button
      label={label}
      onClick={handleClick}
      disabled={loading}
      color="primary"
      variant="contained"
    />
  );
};

export const EditRoleButton = (
  {
    record,
    roleApi,
    roleName,
    confirmationAlert,
  },
) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const action = record.roles.includes(roleApi) ? 'revoke' : 'promote';

  const handleClick = () => {
    // eslint-disable-next-line no-restricted-globals
    if (action === 'promote' && !confirm(confirmationAlert)) {
      return;
    }
    setLoading(true);
    apiFetch(`${record.id}/${action}`, { method: 'PUT', body: { role: roleApi } })
      .then(() => {
        notify(`Role ${action}d with success`, { type: 'info' });
        refresh();
      })
      .catch((e) => {
        notify(`Error when editing the role: ${e.message}`, { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      label={`${action} role - ${roleName}`}
      onClick={handleClick}
      disabled={loading}
      className={`button-managed-admin ${action === 'promote' && 'alert-button'}`}
      variant="contained"
    />
  );
};

export const PostAnalyzer = ({ record }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleClick = (force = false) => {
    setLoading(true);
    apiFetchJson(`${record.id}/analyze?force=${force}`)
      .then((result) => {
        setData(result);
        notify('Post analyzed with success', { type: 'info' });
      })
      .catch((e) => {
        notify(`Error when analyzing the post: ${e.message}`, { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {!data && (
      <Button
        label="Analyze via ML Academy service"
        onClick={() => handleClick()}
        disabled={loading}
        color="secondary"
        variant="contained"
      />
      )}
      {data && (
      <Button
        label="Re-run Analyze (force refresh)"
        onClick={() => handleClick(true)}
        disabled={loading}
        color="secondary"
        variant="contained"
      />
      )}

      {data && <ReactJson src={data} name={null} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} />}
    </>
  );
};

export const PostEstimate = ({ record }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const refresh = useRefresh();

  const handleClick = () => {
    setLoading(true);
    apiFetchJson(`${record.id}/estimate`)
      .then((result) => {
        setData(result);
        notify('Post estimated with success', { type: 'info' });
      })
      .catch((e) => {
        notify(`Error when estimating the post: ${e.message}`, { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {!data && (
      <Button
        label="Estimate via emission rules"
        onClick={() => handleClick()}
        disabled={loading}
        color="secondary"
        variant="contained"
      />
      )}
      {data && (
      <Button
        label="Re-run Indigo estimate"
        onClick={() => handleClick(true)}
        disabled={loading}
        color="secondary"
        variant="contained"
      />
      )}
      {data && (
      <Button
        label="Refresh the page with new external metrics"
        onClick={() => refresh()}
        disabled={loading}
        color="secondary"
        variant="contained"
      />
      )}

      {data && <ReactJson src={data} name={null} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} />}
    </>
  );
};

export const DisableUserButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  let label = '';
  let confirmationAlertText = '';
  if (record) {
    confirmationAlertText = record.disabled
      ? 'Are you sure you want to activate this user?'
      : 'Disabling the user will forbid him to access the app and an email will be sent to him. '
        + 'Moreover, all his posts will be moderated and a notification will be sent to all reporters & requesters.';
    label = record.disabled ? 'Enable this user' : 'Disable the user and moderate all his posts';
  }

  const handleClick = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(confirmationAlertText)) {
      return;
    }
    setLoading(true);
    apiFetch(`${record.id}`, { method: 'PUT', body: { disabled: !record.disabled } })
      .then(() => {
        notify(`${label} with success`, { type: 'info' });
        refresh();
      })
      .catch((e) => {
        notify(`Error when editing user: ${e.message}`, { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      label={label}
      onClick={handleClick}
      disabled={loading}
      color="secondary"
      className="button-managed-admin"
      variant="contained"
    />
  );
};

const SaveAndTranslateButton = (props) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const onSuccess = (response) => {
    const itemId = response && response.data ? response.data['@id'] : undefined;
    if (!itemId) {
      notify('Unable to get the item identifier', { type: 'error' });
      return;
    }

    notify('The item has been saved, translating...', { type: 'info', autoHideDuration: 500 });
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    apiFetch(`${itemId}/translate`, { method: 'PUT' })
      .then(() => {
        notify('All missing translations have been added', { type: 'info' });
      })
      .catch((e) => {
        notify(`Error when translating: ${e.message}`, { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
        refresh();
      });
  };
  return <SaveButton {...props} redirect={false} variant="outlined" disabled={loading} label="Save and translate missing translations" onSuccess={onSuccess} />;
};

export const EditActionsWithTranslate = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <SaveAndTranslateButton />
  </Toolbar>
);

export const RefreshEstimationButton = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    apiFetchJson('/posts/estimate', { method: 'POST' })
      .then(() => {
        notify('Action performed successfully. It may take a few minutes to update', { type: 'info' });
      })
      .catch((e) => {
        notify(`Error when estimate posts: ${e.message}`, { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      label="Refresh all weight and co2 calculated data"
      onClick={() => handleClick()}
      disabled={loading}
      color="secondary"
      variant="contained"
    />
  );
};
