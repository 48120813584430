/* eslint-disable no-console */
import { get, cloneDeep } from 'lodash';

const replaceCityCodesFromPublicSectorInFilters = async (dataProvider, filter) => {
  if (!Object.prototype.hasOwnProperty.call(filter, 'cityCodesFromPublicSector')) {
    return filter;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const propertyPath of Object.keys(filter.cityCodesFromPublicSector)) {
    const userId = filter.cityCodesFromPublicSector[propertyPath];
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await dataProvider.getOne('user', { id: userId });

      filter[propertyPath] = get(result, 'data.managedData.publicSectorData.cityCodes', []);
    } catch (e) {
      console.error(e);
      return filter;
    }

    delete filter.cityCodesFromPublicSector;
  }

  return filter;
};

export const withCustomFilters = (dataProvider) => ({
  ...dataProvider,
  getList: async (resource, params) => {
    try {
      params.filter = await replaceCityCodesFromPublicSectorInFilters(dataProvider, cloneDeep(params.filter || {}));
    } catch (e) {
      console.error(e);
    }
    return dataProvider.getList(resource, params);
  },
});
