import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AppBar } from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import { getJWTExpireDate } from '../helpers';

export default (props) => {
  const JWTExpireDate = moment(getJWTExpireDate(localStorage.getItem('token')));
  const [diffDateInMinutes, setDiffDateInMinutes] = useState(moment.duration(JWTExpireDate.diff(moment())));

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(() => {
      setDiffDateInMinutes(moment.duration(JWTExpireDate.diff(moment())));
    }, MINUTE_MS, diffDateInMinutes);

    return () => clearInterval(interval);
  }, []);

  return (
    <AppBar {...props}>
      <Toolbar>
        <p>Log out in {diffDateInMinutes.days() ? `${diffDateInMinutes.days()}j` : ''} {diffDateInMinutes.hours()}h{(`0${diffDateInMinutes.minutes()}`).slice(-2)}</p>
      </Toolbar>
    </AppBar>
  );
};
