import {
  CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser,
} from '@api-platform/admin';
import {
  ArrayInput,
  Filter,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import React from 'react';
import {
  AdminRuleActionInput,
  ArrayTextField,
  CustomPagination,
} from '../fields/resources';

const AdminRulesFilter = (props) => (
  <Filter {...props}>
    <AdminRuleActionInput source="action" label="Filter by action" alwaysOn />
  </Filter>
);

export const AdminRulesList = (props) => (
  <ListGuesser {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={<AdminRulesFilter />} pagination={<CustomPagination />}>
    <FieldGuesser source="comment" addLabel />
    <FieldGuesser source="action" addLabel />
    <ArrayTextField source="values" addLabel />
    <FieldGuesser source="createdAt" addLabel />
    <FieldGuesser source="updatedAt" addLabel />
  </ListGuesser>
);

export const AdminRuleCreate = (props) => (
  <CreateGuesser {...props} redirect="list">
    <InputGuesser source="comment" />
    <AdminRuleActionInput source="action" required />
    <ArrayInput source="values">
      <SimpleFormIterator>
        <TextInput label="IP, Device ID, word, ..." />
      </SimpleFormIterator>
    </ArrayInput>
  </CreateGuesser>
);

export const AdminRuleEdit = (props) => (
  <EditGuesser {...props} mutationMode="pessimistic" redirect="list">
    <InputGuesser source="comment" />
    <AdminRuleActionInput source="action" required />
    <ArrayInput source="values">
      <SimpleFormIterator>
        <TextInput label="IP, Device ID, word, ..." />
      </SimpleFormIterator>
    </ArrayInput>
  </EditGuesser>
);
