import { FieldGuesser, ListGuesser, ShowGuesser } from '@api-platform/admin';
import {
  DeleteButton,
  ImageField,
  ReferenceField,
  TextField,
  Create,
  FileInput,
  FileField,
  SimpleForm,
  TopToolbar,
  ListButton,
  CreateButton,
  UrlField,
} from 'react-admin';
import React from 'react';
import { CustomPagination } from '../fields/resources';

const MediaObjectCreateActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

const MediaObjectShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <CreateButton basePath={basePath} record={data} />
  </TopToolbar>
);

const MediaObjectListActions = ({ basePath, data }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ContentUrlsActions = ({ record }) => {
  const thumbUrl = `${record.contentUrl.replace('assets.indigo.world/', 'assets.indigo.world/thumb/')}.webp`;
  return (
    <>
      <div>
        <a href={record.contentUrl} target="_blank" className="MuiTypography-colorPrimary">{record.contentUrl}</a>
      </div>
      <div>
        <a
          href={thumbUrl}
          target="_blank"
          className="MuiTypography-colorPrimary"
        >
          {thumbUrl}
        </a>
      </div>
    </>
  );
};

export const MediaObjectList = (props) => (
  <ListGuesser {...props} sort={{ field: 'createdAt', order: 'DESC' }} actions={<MediaObjectListActions />} pagination={<CustomPagination />}>
    <FieldGuesser source="createdAt" addLabel />
    <ReferenceField source="createdBy" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <UrlField source="contentUrl" />
    <ImageField source="contentUrl" label="" />
  </ListGuesser>
);

export const MediaObjectShow = (props) => (
  <ShowGuesser {...props} actions={<MediaObjectShowActions />}>
    <FieldGuesser source="createdAt" addLabel />
    <ReferenceField source="createdBy" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <ContentUrlsActions />
    <ImageField source="contentUrl" label="" />
    <DeleteButton redirect="list" mutationMode="pessimistic" />
  </ShowGuesser>
);

export const MediaObjectCreate = (props) => (
  <Create {...props} actions={<MediaObjectCreateActions />}>
    <SimpleForm>
      <FileInput source="file" label="Upload media" accept="image/jpg,image/jpeg,image/png">
        <FileField source="file" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
