import {
  List,
  Filter,
  NullableBooleanInput,
  TextInput,
  useNotify,
  linkToRecord,
  regex, Button, NumberInput,
} from 'react-admin';
import {
  Card as MaterialCard, TextField as MaterialTextField, FormControl, Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { fetchAllItems, apiFetch } from '../apiFetch';
import {
  StateInput,
  Media,
  CustomPagination,
  transformToTree,
  getCategoryChildren,
  getCategoryDiscriminants,
  PublicSectorUsersCityCodesSelectorInput, CityPostsListActions,
} from '../fields/resources';
import { clientUrl } from '../environment';

import 'react-awesome-slider/dist/styles.css';

const cardStyle = {
  width: 300,
  minHeight: 300,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top',
};

const CityPostCard = ({
  id, data, categories, categoriesOptions,
}) => {
  const [estimatedItemWeight, setEstimatedItemWeight] = useState(undefined);
  const [estimatedPrice, setEstimatedPrice] = useState(undefined);
  const [selectedDiscriminant, setSelectedDiscriminant] = useState(data.discriminants[0] ? data.discriminants[0] : undefined);
  const [selectedCategory, setSelectedCategory] = useState(data.category ? data.category : undefined);
  const [updateForm, setUpdateForm] = useState(false);
  const [firstWeightedCategory, setFirstWeightedCategory] = useState(undefined);
  const [firstPricedCategory, setFirstPricedCategory] = useState(undefined);
  const [selectedCategoryObject, setSelectedCategoryObject] = useState(selectedCategory ? categories.find((cat) => cat['@id'] === selectedCategory) : undefined);
  const [itemsQuantity, setItemsQuantity] = useState(undefined);
  const [sendForm, setSendForm] = useState(false);
  const [estimateWeight, setEstimateWeight] = useState(0);
  const notify = useNotify();

  useEffect(() => {
    setEstimatedPrice(data.metadata.estimatedPrice);
    setItemsQuantity(data.metadata.itemsQuantity);
    setEstimatedItemWeight(data.metadata.estimatedItemWeight);
  }, []);

  const sending = () => {
    const metadata = {};
    if (estimatedPrice) {
      metadata.estimatedPrice = parseFloat(estimatedPrice);
    }
    if (itemsQuantity) {
      metadata.itemsQuantity = parseInt(itemsQuantity, 10);
    }
    if (estimatedItemWeight) {
      metadata.estimatedItemWeight = parseFloat(estimatedItemWeight);
    }
    const category = selectedCategory;
    const discriminants = selectedDiscriminant ? [selectedDiscriminant] : [];
    const body = { metadata, category, discriminants };

    apiFetch(data['@id'], { method: 'PUT', body })
      .then(() => {
        notify(`Post "${data.title}" was saved with success`, { type: 'info' }, { allowMissing: true });
      }).catch((e) => {
        notify(`Error: ${e.message}`, { type: 'warning' }, { allowMissing: true });
      });
  };

  const handler = (event) => {
    if (!event.target.value) return;
    sending();
  };

  const onSelectedCategory = async (event) => {
    if (!event.value) return;
    setSelectedCategory(event.value);
    setUpdateForm(true);
  };

  const onSelectedDiscriminant = async (event) => {
    if (!event.value) return;
    setSelectedDiscriminant(event.value);
    setUpdateForm(true);
  };

  const selectCustomStyles = {
    group: (provided) => ({
      ...provided,
      borderBottom: '1px dotted black',
      borderTop: '1px dotted black',
      padding: 20,
      color: 'black',
      textAlign: 'left',
    }),
    menu: (provided) => ({
      ...provided,
      'z-index': '3',
      textAlign: 'left',
    }),
  };
  const numberFormat = (number) => (Number.isInteger(parseFloat(number)) ? number : parseFloat(number).toFixed(2));
  const displayWeight = (weight) => (weight >= 1 ? `${numberFormat(weight)} Kg` : `${numberFormat(weight * 1000)} g`);
  const getFirstWeightedCategory = (category) => {
    if (!category) {
      return null;
    }
    if (category.itemDefaultWeight !== null) {
      return category;
    }
    if (category.itemMinWeight !== null && category.itemMaxWeight > 0) {
      return category;
    }

    if (!category.parent) {
      return null;
    }

    return getFirstWeightedCategory(category.parent);
  };

  const getFirstPricedCategory = (category) => {
    if (!category) {
      return null;
    }
    if (category.itemDefaultPrice !== null) {
      return category;
    }
    if (!category.parent) {
      return null;
    }

    return getFirstPricedCategory(category.parent);
  };

  useEffect(() => {
    if (!updateForm) return;
    setSelectedDiscriminant(undefined);
    setSelectedCategoryObject(categories.find((cat) => cat['@id'] === selectedCategory));
    setUpdateForm(false);
    setSendForm(true);
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedCategoryObject(categories.find((cat) => cat['@id'] === selectedCategory));
  }, [categories]);

  useEffect(() => {
    if (!selectedCategoryObject) { return; }
    const weightedCategory = getFirstWeightedCategory(selectedCategoryObject);

    if (!weightedCategory) {
      setFirstWeightedCategory(selectedCategoryObject);
      setEstimateWeight(selectedCategoryObject.itemDefaultWeight ? selectedCategoryObject.itemDefaultWeight : (selectedCategoryObject.itemMinWeight + selectedCategoryObject.itemMaxWeight) / 2);
      return;
    }

    setEstimateWeight(weightedCategory.itemDefaultWeight ? weightedCategory.itemDefaultWeight : (weightedCategory.itemMinWeight + weightedCategory.itemMaxWeight) / 2);
    setFirstWeightedCategory(weightedCategory);
  }, [selectedCategoryObject]);

  useEffect(() => {
    const pricedCategory = getFirstPricedCategory(selectedCategoryObject);
    setFirstPricedCategory(pricedCategory || selectedCategoryObject);
  }, [selectedCategoryObject]);

  useEffect(() => {
    if (!updateForm) return;
    setUpdateForm(false);
    setSendForm(true);
  }, [selectedDiscriminant]);

  useEffect(() => {
    if (!sendForm) return;
    sending();
    setSendForm(false);
  }, [sendForm]);

  const discriminantOptions = selectedCategoryObject ? getCategoryDiscriminants(categories, selectedCategoryObject).map((discriminant) => ({ value: discriminant['@id'], label: discriminant.name })) : [];
  return (
    <MaterialCard key={id} style={cardStyle}>
      <Media displaySlider record={data} resource="resource" className="media" />
      <Typography className="created-at">Created at : {moment(data.createdAt).format('DD/MM/YYYY')}</Typography>
      <h3 className="clear-both title">{data.title}</h3>
      <Typography className="description">{data.description}</Typography>
      {(estimatedItemWeight > 0 || estimateWeight > 0) && (
        <h4 className="align-center average-info">
          {itemsQuantity || 1} X {estimatedItemWeight ? displayWeight(estimatedItemWeight) : displayWeight(estimateWeight)}
        </h4>
      )}
      {(estimatedPrice > 0 || (firstPricedCategory && firstPricedCategory.itemDefaultPrice > 0)) && (
        <h4 className="align-center average-info">
          {estimatedPrice > 0 ? estimatedPrice : (itemsQuantity || 1) * (firstPricedCategory.itemDefaultPrice || 0) }€
        </h4>
      )}
      {data.quantity && data.estimatedWeight && (
      <Typography className="estimate-by-user">
        Estimate by user : {data.quantity} X {displayWeight(data.estimatedWeight)}
      </Typography>
      )}
      <div className="form-row align-center">
        <FormControl>
          <Select
            options={categoriesOptions}
            value={categoriesOptions.find((cat) => cat.value === selectedCategory)}
            className="select-search"
            id="category"
            styles={selectCustomStyles}
            onChange={(e) => {
              onSelectedCategory(e);
            }}
          />
        </FormControl>

        <FormControl>
          <MaterialTextField
            className="input"
            label="Total price €"
            type="number"
            variant="filled"
            size="small"
            onBlur={handler}
            value={estimatedPrice}
            inputProps={{ inputMode: 'decimal', min: 0 }}
            onChange={(e) => setEstimatedPrice(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <MaterialTextField
            fullWidth={false}
            label="Quantity"
            type="number"
            variant="filled"
            size="small"
            className="input"
            onBlur={handler}
            value={itemsQuantity}
            inputProps={{ inputMode: 'numeric', min: 1 }}
            onChange={(e) => setItemsQuantity(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <MaterialTextField
            fullWidth={false}
            label="Unit weight kg"
            type="number"
            variant="filled"
            size="small"
            className="input"
            onChange={(e) => setEstimatedItemWeight(e.target.value)}
            onBlur={handler}
            inputProps={{ inputMode: 'decimal', min: 0 }}
            value={estimatedItemWeight}
          />
        </FormControl>

        {firstWeightedCategory
        && (
        <Typography className="min-max-average">
          min: {displayWeight(firstWeightedCategory.itemMinWeight)},
          max: {displayWeight(firstWeightedCategory.itemMaxWeight)},
          average: {displayWeight((firstWeightedCategory.itemMinWeight + firstWeightedCategory.itemMaxWeight) / 2)}
          {firstWeightedCategory.itemDefaultWeight && <span>, default: {displayWeight(firstWeightedCategory.itemDefaultWeight)}</span>}
        </Typography>
        )}
        {discriminantOptions.length > 0 && (
        <FormControl>
          <Select
            placeholder="Select discriminant..."
            options={discriminantOptions}
            value={discriminantOptions.find((discriminant) => discriminant.value === selectedDiscriminant)}
            className="select-search"
            styles={selectCustomStyles}
            onChange={(e) => {
              onSelectedDiscriminant(e);
            }}
          />
        </FormControl>
        )}
        <div className="buttons-container">
          <Button href={`${clientUrl}${data.id}?estimate=true`} className="button" target="_blank" rel="noopener noreferrer" label="View in app" />
          <Button href={linkToRecord('/#/posts', data.id, 'show')} className="button" target="_blank" rel="noopener noreferrer" label="View in supervisor" />
        </div>
      </div>
    </MaterialCard>
  );
};

const CityPostDataGrid = ({
  ids, data, resource, basePath,
}) => {
  const [categories, setCategories] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const objectCategories = await fetchAllItems('/categories?type=object', { method: 'GET' });
      let categoriesArray = [];
      transformToTree(objectCategories).forEach((cat) => {
        if (cat['@id']) {
          categoriesArray.push({
            value: cat['@id'],
            label: cat.translations.fr,
            type: cat.type,
          });
          const subCategories = getCategoryChildren(objectCategories, cat);
          categoriesArray = subCategories ? categoriesArray.concat(subCategories) : categoriesArray;
        }
      });
      setCategoriesOptions(categoriesArray);
      setCategories(objectCategories);
    };

    fetchCategories();
  }, []);

  return (
    <div style={{ margin: '1em' }}>
      {ids.map((id) => (
        <CityPostCard
          key={id}
          {...{
            id, data: data[id], resource, basePath, categories, categoriesOptions,
          }}
        />
      ))}
    </div>
  );
};

const validatePostId = regex(/^(\/?posts\/)?[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i, 'Must be a valid post id');
const PostFilter = (props) => (
  <Filter {...props}>
    <PublicSectorUsersCityCodesSelectorInput source="address.cityCode" label="City Code" alwaysOn />
    <StateInput source="state" alwaysOn />
    <TextInput source="title" alwaysOn />
    <TextInput source="address.postalCode" label="Postal Code" />
    <TextInput source="id" label="Post id" validate={validatePostId} />

    <NullableBooleanInput source="exists[metadata.calculatedWeight]" defaultValue={null} label="With a calculated weight (kg)" />
    <NumberInput source="metadata.calculatedWeight[gte]" label="Min calculated weight (kg)" />
    <NumberInput source="metadata.calculatedWeight[lte]" label="Max calculated weight (kg)" />

    <NullableBooleanInput source="exists[metadata.calculatedPrice]" defaultValue={null} label="With a calculated price (€)" />
    <NumberInput source="metadata.calculatedPrice[gte]" label="Min calculated price" />
    <NumberInput source="metadata.calculatedPrice[lte]" label="Max calculated price" />

    <NullableBooleanInput source="exists[metadata.estimatedItemWeight]" defaultValue={null} label="With an estimated weight set by admin" />
    <NumberInput source="metadata.estimatedItemWeight[gte]" label="Min estimated weight set by admin" />
    <NumberInput source="metadata.estimatedItemWeight[lte]" label="Max estimated weight set by admin" />

    <NullableBooleanInput source="exists[metadata.estimatedItemWeight]" defaultValue={null} label="With an estimated weight set by admin" />
    <NumberInput source="metadata.estimatedItemWeight[gte]" label="Min estimated weight set by admin" />
    <NumberInput source="metadata.estimatedItemWeight[lte]" label="Max estimated weight set by admin" />

    <NullableBooleanInput source="exists[estimatedWeight]" defaultValue={null} label="With an estimated weight set by user" />
    <NumberInput source="estimatedWeight[gte]" label="Min estimated weight set by user" />
    <NumberInput source="estimatedWeight[lte]" label="Max estimated weight set by user" />

    <NullableBooleanInput source="exists[metadata.estimatedPrice]" defaultValue={null} label="With an estimated price set by admin" />
    <NumberInput source="metadata.estimatedPrice[gte]" label="Min price set by admin" />
    <NumberInput source="metadata.estimatedPrice[lte]" label="Max price set by admin" />
  </Filter>
);

const permanentFilter = { categoryType: 'object' };
const defaultFilterValues = { state: 'finished' };

export const CityPostsList = (props) => (
  <List
    {...props}
    filter={permanentFilter}
    filterDefaultValues={defaultFilterValues}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<PostFilter />}
    exporter={false}
    pagination={<CustomPagination />}
    className="city-post"
    actions={<CityPostsListActions />}
  >
    <CityPostDataGrid {...props} />
  </List>
);
