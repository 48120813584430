import { apiEntrypoint } from './environment';

const authenticationTokenUri = `${apiEntrypoint}/auth/token`;

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

export default {
  // authentication
  login: (params) => {
    const { username, password } = params;
    const request = new Request(authenticationTokenUri, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);

        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem('token', token); // The JWT token is stored in the browser's local storage
        // Reload the page in order to fix a super annoying infinite refresh loop
        // TODO See if the bug still happens sometimes, and remove or uncomment this line.
        // window.location.reload();
      });
  },
  checkError: (error) => {
    // error.response.status for Production, error.status for dev: there is an issue in some vendors
    const status = typeof error.response !== 'undefined' ? error.response.status : error.status;
    if (status === 401) {
      localStorage.removeItem('token');
      return Promise.reject({ redirectTo: '/login' });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem('token') ? Promise.resolve() : Promise.reject()),
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const data = parseJwt(localStorage.getItem('token'));
      return Promise.resolve({ id: data.id, fullName: 'Admin' }); // { id, fullName, avatar }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization
  getPermissions: () => {
    try {
      const data = parseJwt(localStorage.getItem('token'));
      return Promise.resolve(data.roles);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
