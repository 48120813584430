import React from 'react';
import {
  BrowserRouter, Switch, Route, Link,
} from 'react-router-dom';
import { HydraAdmin, hydraDataProvider } from '@api-platform/admin';
import { Resource, Admin } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { apiEntrypoint } from './environment';
import authProvider from './authProvider';
import './style.css';
import { CategoryThematicCreate, CategoryThematicEdit, CategoryThematicsList } from './resources/CategoryThematics';
import { CategoriesList, CategoryCreate, CategoryEdit } from './resources/Categories';
import { CategoryDiscriminantCreate, CategoryDiscriminantEdit, CategoryDiscriminantsList } from './resources/CategoryDiscriminants';
import { PostEdit, PostsList, PostShow } from './resources/Posts';
import { CityPostsList } from './resources/CityPosts';
import { UserEdit, UserShow, UsersList } from './resources/Users';
import { MediaObjectList, MediaObjectShow, MediaObjectCreate } from './resources/MediaObjects';
import { withCityPosts } from './dataProviders/withCityPosts';
import { withCustomFilters } from './dataProviders/withCustomFilters';
import { ReportsList, ReportShow } from './resources/Reports';
import { PostRequestList, PostRequestShow } from './resources/PostRequests';
import { UserReviewList, UserReviewShow } from './resources/UserReviews';
import { apiDocumentationParser, fetchHydra } from './apiFetch';
import { IndigoLayout, CircularLayout } from './layout/Layout';
import { AdminRuleCreate, AdminRuleEdit, AdminRulesList } from './resources/AdminRules';
import { UserLogShow, UserLogsList } from './resources/UserLogs';
import { EmissionRuleCreate, EmissionRuleEdit, EmissionRulesList } from './resources/EmissionRules';
import { withGetManyPaginator } from './dataProviders/withGetManyPaginator';
import { LocalitiesList, LocalityCreate, LocalityEdit } from './resources/Localities';
import { CustomersList } from './resources/Customers';
import circularDataProvider from './dataProviders/circularDataProvider';

// Silence missing translation warning
const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });
const dataProvider = withCustomFilters(withCityPosts(withGetManyPaginator(hydraDataProvider(apiEntrypoint, fetchHydra, apiDocumentationParser))));

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/indigo">
        <HydraAdmin
          entrypoint={apiEntrypoint}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          layout={IndigoLayout}
        >
          <Resource name="users" list={UsersList} edit={UserEdit} show={UserShow} />
          <Resource name="posts" options={{ label: 'Posts' }} list={PostsList} show={PostShow} edit={PostEdit} />
          <Resource name="city_posts" options={{ label: 'City post' }} list={CityPostsList} />
          <Resource name="post_requests" list={PostRequestList} show={PostRequestShow} />
          <Resource name="category_discriminants" list={CategoryDiscriminantsList} create={CategoryDiscriminantCreate} edit={CategoryDiscriminantEdit} />
          <Resource name="categories" list={CategoriesList} create={CategoryCreate} edit={CategoryEdit} />
          <Resource name="category_thematics" list={CategoryThematicsList} create={CategoryThematicCreate} edit={CategoryThematicEdit} />
          <Resource name="media_objects" list={MediaObjectList} show={MediaObjectShow} create={MediaObjectCreate} />
          <Resource name="reports" list={ReportsList} show={ReportShow} />
          <Resource name="user_reviews" list={UserReviewList} show={UserReviewShow} />
          <Resource name="admin_rules" list={AdminRulesList} create={AdminRuleCreate} edit={AdminRuleEdit} />
          <Resource name="emission_rules" list={EmissionRulesList} create={EmissionRuleCreate} edit={EmissionRuleEdit} />
          <Resource name="user_logs" list={UserLogsList} show={UserLogShow} />
          <Resource name="localities" list={LocalitiesList} create={LocalityCreate} edit={LocalityEdit} />
          <Resource name="device_tokens" />
        </HydraAdmin>
      </Route>

      <Route path="/circular">
        <Admin
          entrypoint={apiEntrypoint}
          dataProvider={circularDataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          layout={CircularLayout}
        >
          <Resource name="customers" list={CustomersList} />
        </Admin>
      </Route>

      <Route path="/">
        <nav>
          <ul>
            <li>
              <Link to="/indigo">Indigo</Link>
            </li>
            <li>
              <Link to="/circular">Circular</Link>
            </li>
          </ul>
        </nav>
      </Route>
    </Switch>
  </BrowserRouter>
);
