export const getProp = (object, path, defaultVal) => {
  // Almost the same function as _.get()
  if (typeof object === 'undefined') {
    return object;
  }

  const pathArr = Array.isArray(path) ? path : path.split('.').filter((i) => i.length);

  if (!pathArr.length) {
    return object === undefined ? defaultVal : object;
  }

  return getProp(object[pathArr.shift()], pathArr, defaultVal);
};

function addParameterToUrl(url, key, value) {
  let baseUrl = url;
  if (Array.isArray(value)) {
    value.forEach((val) => { baseUrl = addParameterToUrl(baseUrl, key, val); });
  } else if (typeof value === 'object') {
    baseUrl = addParameterToUrl(baseUrl, `${key}[${Object.keys(value)[0]}]`, Object.values(value)[0]);
  } else {
    baseUrl += baseUrl.split('?')[1] ? '&' : '?';
    baseUrl += `${key}=${encodeURIComponent(value)}`;
  }

  return baseUrl;
}

export function addParametersToUrl(baseUrl, params) {
  let url = baseUrl;
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      url = addParameterToUrl(url, key, params[key]);
    }
  });
  return url;
}

export function getJWTExpireDate(jwtToken) {
  if (!jwtToken) {
    return null;
  }

  const [, payload] = jwtToken.split('.');
  const { exp: expires } = JSON.parse(window.atob(payload));
  if (typeof expires === 'number') {
    return new Date(expires * 1000);
  }

  return null;
}
