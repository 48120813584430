import React from 'react';
import {
  TextField, TextInput,
  Filter,
  Datagrid,
  List,
} from 'react-admin';
import {
  ListActions,
  CustomPagination,
} from '../fields/resources';

const CustomersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name (attention aux accents)" source="name" alwaysOn />
    <TextInput source="urlReference" alwaysOn />
    <TextInput source="catchphrase" alwaysOn />
  </Filter>
);

export const CustomersList = (props) => (
  <List {...props} filters={<CustomersFilter />} actions={<ListActions />} pagination={<CustomPagination />}>
    <Datagrid>
      <TextField source="name" sortable={false} />
      <TextField source="logo" sortable={false} />
      <TextField source="cover" sortable={false} />
      <TextField source="color" sortable={false} />
      <TextField source="urlReference" sortable={false} />
      <TextField source="catchphrase" sortable={false} />
    </Datagrid>
  </List>
);
