export const withCityPosts = (dataProvider) => ({
  ...dataProvider,
  getList: (resource, params) => {
    if (resource === 'city_posts') {
      return dataProvider.getList('posts', {
        ...params,
      });
    }
    // fallback to the default implementation
    return dataProvider.getList(resource, params);
  },
  update: (resource, params) => {
    if (resource === 'city_posts') {
      return dataProvider.update('posts', params);
    }

    // fallback to the default implementation
    return dataProvider.update(resource, params);
  },
});
