import {
  DateField,
  Filter, ReferenceField, TextField, TextInput,
} from 'react-admin';
import { FieldGuesser, ListGuesser, ShowGuesser } from '@api-platform/admin';
import React from 'react';
import { CustomPagination, JsonField } from '../fields/resources';

const UserLogsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="action" alwaysOn />
    <TextInput source="deviceId" alwaysOn />
    <TextInput source="ip" alwaysOn />
    <TextInput source="user" />
  </Filter>
);

export const UserLogsList = (props) => (
  <ListGuesser {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={<UserLogsFilter />} pagination={<CustomPagination />}>
    <FieldGuesser source="action" addLabel />
    <ReferenceField source="user" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="appVersion" addLabel />
    <FieldGuesser source="deviceId" addLabel />
    <FieldGuesser source="ip" addLabel />
    <DateField source="createdAt" showTime />
  </ListGuesser>
);

export const UserLogShow = (props) => (
  <ShowGuesser {...props}>
    <ReferenceField source="user" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="action" addLabel />
    <FieldGuesser source="appVersion" addLabel />
    <FieldGuesser source="deviceId" addLabel />
    <FieldGuesser source="ip" addLabel />
    <DateField source="createdAt" showTime />
    <JsonField source="data" addLabel />
  </ShowGuesser>
);
