import { FieldGuesser, InputGuesser, ShowGuesser } from '@api-platform/admin';
import {
  SelectInput,
  TextInput,
  ReferenceField,
  SimpleForm,
  BooleanField,
  Edit,
  FormDataConsumer,
  DeleteButton,
  Filter,
  ArrayInput,
  SimpleFormIterator,
  PasswordInput,
  DateInput,
  NullableBooleanInput,
  BooleanInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  Pagination,
  List,
  EditButton,
  required as requiredValidate,
  TopToolbar,
} from 'react-admin';
import React, { useCallback } from 'react';
import { Typography, Button } from '@material-ui/core';
import { useForm } from 'react-final-form';
import {
  JsonField,
  ListActions,
  Media,
  RolesTypeInput,
  CustomPagination, PublicSectorUsersCityCodesSelectorInput,
} from '../fields/resources';
import { cityUrl, clientUrl } from '../environment';
import { DisableUserButton, EditRoleButton } from '../fields/actions';

const uniqid = require('uniqid');

const managedUserTypes = [
  { id: 'ngo', name: 'NGO' },
  { id: 'public_sector', name: 'Public Sector (Collectivity)' },
  { id: 'public_structure', name: 'Public Structure' },
  { id: 'reuse_organization', name: 'Reuse Organization' },
];

const GenerateNewUrlButton = ({ variant, ...props }) => {
  const form = useForm();

  const handleClick = useCallback(() => {
    form.change('managedData.publicSectorData.cityKey', uniqid());
  }, [form]);

  return (
    <Button variant="outlined" onClick={handleClick} {...props}>Generate new key for Indigo City</Button>
  );
};

const UsersFilter = (props) => (
  <Filter {...props}>
    <InputGuesser label="Name (attention aux accents)" source="name" alwaysOn />
    <InputGuesser source="email" alwaysOn />
    <PublicSectorUsersCityCodesSelectorInput source="address.cityCode" alwaysOn />
    <SelectInput
      source="managedData.type"
      label="Managed Type"
      choices={managedUserTypes}
    />
    <TextInput label="Phone" source="personalData.phone" />
    <BooleanInput source="managed" />
    <BooleanInput source="disabled" />
    <NullableBooleanInput label="Deleted" source="exists[deletedAt]" />
    <RolesTypeInput source="roles" />
    <TextInput label="created before" source="createdAt.before" placeholder="ex: 30-03-2021" />
    <TextInput label="created after" source="createdAt.after" placeholder="ex: 30-03-2021" />
    <TextInput source="address.postalCode" label="Postal code" />
  </Filter>
);

export const UsersList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={<UsersFilter />} actions={<ListActions />} pagination={<CustomPagination />}>
    <Datagrid>
      <FieldGuesser source="name" sortable={false} />
      <FieldGuesser source="email" sortable={false} />
      <FieldGuesser source="managed" sortable={false} />
      <BooleanField source="disabled" sortable={false} />
      <BooleanField source="managedData.certified" sortable={false} label="Certified" />
      <FieldGuesser source="deletionReason" sortable={false} />
      <TextField source="managedData.type" sortable={false} label="Managed type" />
      <FieldGuesser source="createdAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="show">
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.managed) {
            return null;
          }

          return (
            <>
              <TextInput source="personalData.firstname" label="Firstname" validate={requiredValidate()} autoComplete="new-firstname" />
              <TextInput source="personalData.lastname" label="Lastname" autoComplete="new-lastname" />
              <TextInput source="personalData.phone" type="tel" label="Phone number" autoComplete="new-phone" />
              <DateInput source="personalData.birthday" label="Birthday" />
              <NullableBooleanInput source="personalData.newsletterSubscribed" label="Suscribed to the newsletter" />
              <SelectInput
                source="personalData.gender"
                label="Gender"
                choices={[
                  { id: 'male', name: 'Male' },
                  { id: 'female', name: 'Female' },
                  { id: 'other', name: 'Other' },
                ]}
              />
            </>
          );
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => {
          if (!formData.managed) {
            return null;
          }

          return (
            <>
              <TextInput source="name" validate={requiredValidate()} autoComplete="new-name" />
              {/* TODO Return all choices in the API doc */}
              <SelectInput
                source="managedData.type"
                label="Type"
                choices={managedUserTypes}
              />
              <TextInput source="managedData.activity" label="Activity" />
              <TextInput source="managedData.website" label="Website" />
            </>
          );
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => {
          if (!formData.managed) {
            return null;
          }

          return (
            <>
              <TextInput source="managedData.contact.firstname" label="Contact firstname" validate={requiredValidate()} autoComplete="new-contact-firstname" />
              <TextInput source="managedData.contact.lastname" label="Contact lastname" validate={requiredValidate()} autoComplete="new-contact-lastname" />
              <TextInput source="managedData.contact.phone" label="Contact phone" validate={requiredValidate()} autoComplete="new-contact-phone" />
              <TextInput source="managedData.contact.email" label="Contact email" validate={requiredValidate()} autoComplete="new-contact-email" />
              <TextInput source="managedData.contact.occupation" label="Contact occupation" />
            </>
          );
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => {
          if (!formData.managed) {
            return null;
          }

          return (
            <>
              <BooleanInput source="managedData.certified" label="Certified" />
            </>
          );
        }}
      </FormDataConsumer>

      <TextInput source="locale" />
      <TextInput source="aboutMe" parse={(value) => value} multiline />
      <TextInput source="aboutMeOnIndigo" parse={(value) => value} multiline />
      <PasswordInput source="plainPassword" label="New password" autoComplete="new-password" />
      <BooleanInput source="passwordMustBeChanged" label="The user must change its password after the next login" />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.managed && formData.email === null) {
            return null;
          }
          return <TextInput source="email" label="Email" validate={requiredValidate()} autoComplete="new-email" />;
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => {
          if (!formData.managed || formData.managedData === undefined) {
            return null;
          }
          if (formData.managedData.type !== 'public_sector') {
            return null;
          }

          return (
            <>
              <div>
                {GenerateNewUrlButton(props)}
              </div>
              <div>
                <TextInput source="managedData.publicSectorData.cityKey" autoComplete="new-citykey" label="Indigo city key" />
              </div>

              <ArrayInput source="managedData.publicSectorData.cityCodes" label="City codes">
                <SimpleFormIterator>
                  <TextInput label="City code (code INSEE)" autoComplete="new-cityCode" />
                </SimpleFormIterator>
              </ArrayInput>

              <TextInput source="managedData.publicSectorData.welcomeImageUrl" label="Indigo city welcome image url" fullWidth />
              <TextInput source="managedData.publicSectorData.welcomeText" label="Indigo city welcome text" multiline fullWidth />

              <hr />
              <Typography>
                Add promotional card and modal on app home for a public sector
              </Typography>
              <Typography>
                - To display the card, you must fill in the background image at least.
              </Typography>
              <Typography>
                - To display the modal you must fill in either the modal image and/or the modal text
              </Typography>
              <TextInput source="managedData.publicSectorData.promotionalBgImage" label="Background image" fullWidth />
              <TextInput source="managedData.publicSectorData.promotionalBgImageLargeScreen" label="Background image large screen" fullWidth />
              <TextInput source="managedData.publicSectorData.promotionalModalImage" label="Modal image" fullWidth />
              <TextInput source="managedData.publicSectorData.promotionalModalText" label="Modal text" fullWidth multiline />

              <hr />
              <Typography>
                All users located in a public_sector user area can see a custom image in the first homepage&apos;s grid cell.<br />
                You can upload an image directly in the <strong>Media objects</strong> page.<br />
                A good image size could be 1000x600, with a centered always-displayed-area (without margin) of 270x600
              </Typography>
              <TextInput source="managedData.publicSectorData.firstCellImage" label="Homepage's first cell image URL" fullWidth />
              <TextInput source="managedData.publicSectorData.firstCellRedirectUrl" label="Redirect URL of the homepage's first cell image" fullWidth />

              <hr />
            </>
          );
        }}
      </FormDataConsumer>

      <Typography>You must set a DeletionReason and save this page before deleting the user</Typography>
      <TextInput source="deletionReason" />
    </SimpleForm>
  </Edit>
);

const UserDataField = ({ record }) => {
  if (!record) {
    return null;
  }

  if (record.managed) {
    return <JsonField record={record} source="managedData" label="Managed data" />;
  }

  return <JsonField record={record} source="personalData" label="Personal data" />;
};

const UserShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />

    {data && (
    <>
      <Button href={`${clientUrl}${data.id}`} className="button-size" color="primary" target="_blank" rel="noopener noreferrer">View in app</Button>
      <DisableUserButton record={data} />
    </>
    )}
  </TopToolbar>
);

const CityURL = ({ record }) => {
  if (!record.managedData || !record.managedData.publicSectorData || !record.managedData.publicSectorData.cityKey) {
    return <></>;
  }

  return (
    <div>
      <p>City URL</p>
      <Typography>
        <a
          href={`${cityUrl}/${record.id.replace('/users/', '')}/${record.managedData.publicSectorData.cityKey}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${cityUrl}/${record.id.replace('/users/', '')}/${record.managedData.publicSectorData.cityKey}`}
        </a>
      </Typography>
      <p>City URL with estimated data</p>
      <Typography>
        <a
          href={`${cityUrl}/${record.id.replace('/users/', '')}/${record.managedData.publicSectorData.cityKey}?showEstimates=true`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${cityUrl}/${record.id.replace('/users/', '')}/${record.managedData.publicSectorData.cityKey}?showEstimates=true`}
        </a>
      </Typography>
    </div>
  );
};

export const UserShow = (props) => (
  <ShowGuesser actions={<UserShowActions />} {...props}>
    <Media displaySlider className="media-big" />
    <FieldGuesser source="name" addLabel />
    <FieldGuesser source="managed" addLabel />
    <FieldGuesser source="email" addLabel />
    <JsonField source="roles" addLabel />
    <EditRoleButton record="record" roleApi="ROLE_ADMIN" roleName="Admin" confirmationAlert="Are you sure you want to add the admin role to this user? He will have access to all the data!" />
    <EditRoleButton
      record="record"
      roleApi="ROLE_CREATE_USER"
      roleName="Can create other users"
      confirmationAlert="Are you sure you want to add the create_user role to this user? He will be able to create new users!"
    />
    <EditRoleButton
      record="record"
      roleApi="ROLE_ANALYZER"
      roleName="Can see analyzer data"
      confirmationAlert="Are you sure you want to add the analyzer role to this user? He will be able to access to the posts 'Estimate CO2' buttons"
    />
    <EditRoleButton
      record="record"
      roleApi="ROLE_MODERATOR"
      roleName="Can moderate posts when report it directly in app"
      confirmationAlert="Are you sure you want to add the moderator role to this user? He will be able to moderate posts when reporting them directly in app"
    />
    <EditRoleButton
      record="record"
      roleApi="ROLE_GLOBAL_NOTIFIER"
      roleName="Can send global notifications to all users"
      confirmationAlert="Are you sure you want to add the global notifier role to this user? He will be able to send notifications to all users from the app"
    />
    <UserDataField source="data" label="User data" />
    <CityURL source="data" />

    <FieldGuesser source="aboutMe" addLabel />
    <FieldGuesser source="aboutMeOnIndigo" addLabel />
    <FieldGuesser source="allowPushNotifications" addLabel />
    <JsonField source="disabledPushNotifications" addLabel />
    <FieldGuesser source="allowMailNotifications" addLabel />
    <JsonField source="disabledMailNotifications" addLabel />
    <JsonField source="preferences" addLabel />
    <FieldGuesser source="locale" addLabel />
    <JsonField source="address" addLabel />
    <Media />
    <FieldGuesser source="createdAt" addLabel />
    <FieldGuesser source="updatedAt" addLabel />
    <JsonField source="status" />
    <FieldGuesser source="favoriteCategoryThematics" addLabel />
    <BooleanField source="disabled" addLabel />
    <BooleanField source="passwordMustBeChanged" addLabel />
    <FieldGuesser source="deleted" addLabel />
    <FieldGuesser source="deletionReason" addLabel />

    <ReferenceManyField
      label="Posts"
      reference="posts"
      target="createdBy"
      perPage={10}
      filter={{ itemsPerPage: 10 }}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <FieldGuesser source="state" addLabel />
        <FieldGuesser source="title" addLabel />
        <FieldGuesser source="type" addLabel />
        <FieldGuesser source="categoryType" addLabel />
        <FieldGuesser source="category" addLabel />
        <DateField source="createdAt" showTime />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>

    <ReferenceManyField
      label="Post requests"
      reference="post_requests"
      target="user"
      perPage={10}
      filter={{ itemsPerPage: 10 }}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <FieldGuesser source="state" addLabel />
        <ReferenceField source="post" reference="posts" link="show">
          <TextField source="title" />
        </ReferenceField>
        <DateField source="createdAt" showTime />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>

    <ReferenceManyField
      label="Received Reviews"
      reference="user_reviews"
      target="reviewedUser"
      perPage={5}
      filter={{ itemsPerPage: 5 }}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="type" />
        <ReferenceField source="reviewedPostRequest" reference="post_requests" link="show">
          <ReferenceField source="post" reference="posts" link={false}>
            <TextField source="title" />
          </ReferenceField>
        </ReferenceField>
        <TextField source="comment" />
        <ReferenceField source="createdBy" reference="users" link="show">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" showTime />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>

    <ReferenceManyField
      label="Given Reviews"
      reference="user_reviews"
      target="createdBy"
      perPage={5}
      filter={{ itemsPerPage: 5 }}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="type" />
        <ReferenceField source="reviewedUser" reference="users" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="reviewedPostRequest" reference="post_requests" link="show">
          <ReferenceField source="post" reference="posts" link={false}>
            <TextField source="title" />
          </ReferenceField>
        </ReferenceField>
        <TextField source="comment" />
        <DateField source="createdAt" showTime />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>

    <ReferenceManyField
      label="Reports received"
      reference="reports"
      target="reportedUser"
      perPage={5}
      filter={{ itemsPerPage: 5 }}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <FieldGuesser source="reason" addLabel />
        <FieldGuesser source="creatorComment" addLabel />
        <FieldGuesser source="createdAt" addLabel />
        <ReferenceField source="createdBy" reference="users" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>

    <ReferenceManyField
      label="Reports created"
      reference="reports"
      target="createdBy"
      perPage={5}
      filter={{ itemsPerPage: 5 }}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <ReferenceField source="reportedPost" reference="posts" link="show">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField source="reportedUser" reference="users" link="show">
          <TextField source="name" />
        </ReferenceField>
        <FieldGuesser source="reason" addLabel />
        <FieldGuesser source="creatorComment" addLabel />
        <FieldGuesser source="createdAt" addLabel />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>

    <ReferenceManyField
      label="Logs"
      reference="user_logs"
      target="user"
      perPage={5}
      filter={{ itemsPerPage: 5 }}
      pagination={<Pagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="action" />
        <JsonField source="data" collapsed />
        <TextField source="appVersion" />
        <TextField source="ip" />
        <TextField source="deviceId" />
        <DateField source="createdAt" showTime />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>

    <ReferenceManyField
      label="Device tokens"
      reference="device_tokens"
      target="user"
      perPage={5}
      filter={{ itemsPerPage: 5 }}
      pagination={<Pagination />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="platform" />
        <TextField source="token" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <DeleteButton redirect={false} mutationMode="pessimistic" />
      </Datagrid>
    </ReferenceManyField>
  </ShowGuesser>
);
