import { FieldGuesser, ListGuesser } from '@api-platform/admin';
import {
  ReferenceField, Show, SimpleShowLayout, TextField,
} from 'react-admin';
import React from 'react';
import { CustomPagination } from '../fields/resources';

export const UserReviewList = (props) => (
  <ListGuesser {...props} sort={{ field: 'createdAt', order: 'DESC' }} pagination={<CustomPagination />}>
    <ReferenceField source="reviewedPostRequest" reference="post_requests" link="show">
      <ReferenceField source="post" reference="posts" link={false}>
        <TextField source="title" />
      </ReferenceField>
    </ReferenceField>
    <ReferenceField source="reviewedUser" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="type" addLabel />
    <FieldGuesser source="comment" addLabel />
    <FieldGuesser source="createdAt" addLabel />
    <ReferenceField source="createdBy" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
  </ListGuesser>
);

export const UserReviewShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="reviewedPostRequest" reference="post_requests" link="show">
        <ReferenceField source="post" reference="posts" link={false}>
          <TextField source="title" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField source="reviewedUser" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source="type" addLabel />
      <FieldGuesser source="comment" addLabel />
      <FieldGuesser source="createdAt" addLabel />
      <ReferenceField source="createdBy" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
