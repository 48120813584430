import { apiEntrypoint } from '../environment';

export class NotImplementedError extends Error {}

export const convertReactAdminRequestToJsonApiRequest = (type, resource, params) => {
  switch (type) {
    case 'GET_LIST': {
      const {
        pagination: { page, perPage },
        sort,
        filter,
      } = params;

      const query = {
        'page[number]': page,
        'page[size]': perPage,
      };

      Object.keys(filter || {}).forEach((key) => {
        query[`filter[${key}]`] = filter[key];
      });

      if (sort && sort.field) {
        const prefix = sort.order === 'ASC' ? '' : '-';
        query.sort = `${prefix}${sort.field}`;
      }

      return Promise.resolve({
        options: {},
        url: `${apiEntrypoint}/${resource}?${JSON.stringify(query)}`,
      });
    }

    case 'GET_ONE': {
      const { id } = params;
      return Promise.resolve({
        options: {},
        url: `${apiEntrypoint}/${resource}?${id}`,
      });
    }

    default:
      return Promise.reject(new NotImplementedError(`Unsupported Data Provider request type ${type}`));
  }
};

export const fetchApi = async (type, resource, params) => convertReactAdminRequestToJsonApiRequest(type, resource, params).then(({ url }) => fetch(
  url,
  {
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'Access-Control-Allow-Origin': window.location.hostname,
    },
    mode: 'cors',
  },
).then((response) => response.json().then((json) => {
  const obj = {
    data: json.data.map((value) => ({
      id: value.id,
      ...value.attributes,
    })),
    total: json.data.length,
  };
  return obj;
})));

export default {
  getList: (resource, params) => fetchApi('GET_LIST', resource, params),
  getOne: (resource, params) => fetchApi('GET_ONE', resource, params),
  getMany: (resource, params) => fetchApi('GET_MANY', resource, params),
  getManyReference: (resource, params) => fetchApi('GET_MANY_REFERENCE', resource, params),
  create: (resource, params) => fetchApi('UPDATE', resource, params),
  updateMany: (resource, params) => fetchApi('UPDATE_MANY', resource, params),
  delete: (resource, params) => fetchApi('DELETE', resource, params),
  deleteMany: (resource, params) => fetchApi('DELETE_MANY', resource, params),
};
