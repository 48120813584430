// This list is hardcoded as we cannot access the dataProvider parsed schema from here
const hasIdSearchFilter = (resource) => ['posts'].includes(resource);

// This is a copy of the original dataProvider.getMany method, and could be deleted as soon the related PR is merged on api-platform
// See https://github.com/api-platform/admin/pull/420
export const withGetManyPaginator = (dataProvider) => ({
  ...dataProvider,
  getMany: (resource, params) => {
    if (!hasIdSearchFilter(resource) || !params.ids) {
      return dataProvider.getMany(resource, params);
    }

    return dataProvider.getList(resource, {
      pagination: {
        perPage: params.ids.length,
      },
      sort: {},
      filter: {
        id: params.ids,
      },
    });
  },
});
