import {
  CreateGuesser, FieldGuesser, InputGuesser, ListGuesser,
} from '@api-platform/admin';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import { CustomPagination, TranslationsField, TranslationsInput } from '../fields/resources';
import { EditActionsWithTranslate } from '../fields/actions';

export const CategoryDiscriminantsList = (props) => (
  <ListGuesser {...props} sort={{ field: 'order', order: 'ASC' }} pagination={<CustomPagination />}>
    <FieldGuesser source="name" />
    <TranslationsField source="translations" />
  </ListGuesser>
);

export const CategoryDiscriminantCreate = (props) => (
  <CreateGuesser {...props} redirect="edit">
    <InputGuesser source="name" />
    <TranslationsInput source="translations" />
  </CreateGuesser>
);

export const CategoryDiscriminantEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect="list">
    <SimpleForm toolbar={<EditActionsWithTranslate />}>
      <TextInput source="name" disabled />
      <TranslationsInput source="translations" />
    </SimpleForm>
  </Edit>
);
