import {
  CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser,
} from '@api-platform/admin';
import {
  Filter, ReferenceField, TextField,
  TextInput,
} from 'react-admin';
import React from 'react';
import { Typography } from '@material-ui/core';
import {
  CategoryDiscriminantSelectorInput,
  CategorySelectorInput, CategoryTypeInput,
  CustomPagination, EmissionFactorUnitsInput,
} from '../fields/resources';

const EmissionRulesFilter = (props) => (
  <Filter {...props}>
    <CategorySelectorInput source="itemCategory" alwaysOn />
    <CategoryDiscriminantSelectorInput source="itemCategoryDiscriminant" alwaysOn />
    <CategoryTypeInput source="itemCategoryType" />
    <TextInput source="comment" />
  </Filter>
);

export const EmissionRulesList = (props) => (
  <ListGuesser {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={<EmissionRulesFilter />} pagination={<CustomPagination />}>
    <ReferenceField source="itemCategory" reference="categories" link="edit" sortable={false}>
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="itemCategoryDiscriminant" addLabel sortable={false} />
    <FieldGuesser source="emissionFactor" />
    <FieldGuesser source="emissionFactorUnit" label="Unit" sortable={false} />
    <FieldGuesser source="emissionFactorIsPer" label="Per" sortable={false} />
    <FieldGuesser source="emissionFactorIncertitudePercent" label="% Incertitude" sortable={false} />
    <FieldGuesser source="averageWeight" />
    <FieldGuesser source="createdAt" addLabel />
    <FieldGuesser source="updatedAt" addLabel />
  </ListGuesser>
);

const generateInputs = () => [
  <CategoryTypeInput source="itemCategoryType" key="itemCategoryType" label="Item Category type" defaultValue="object" required />,
  <CategorySelectorInput source="itemCategory" key="itemCategory" label="Item Category" helperText="Required" required />,
  <CategoryDiscriminantSelectorInput source="itemCategoryDiscriminant" key="itemCategoryDiscriminant" helperText="If null, the rule is about all items for the chosen category" />,
  <InputGuesser source="atmoterraCategory" key="atmoterraCategory" helperText="The Atmoterra subcategory, used in order to map posts with old categories with the new ones" />,
  <InputGuesser source="comment" key="comment" multiline />,
  <InputGuesser source="emissionFactor" key="emissionFactor" helperText="Unit: kgCO2e" required />,
  <EmissionFactorUnitsInput source="emissionFactorIsPer" key="emissionFactorIsPer" required />,
  <InputGuesser source="emissionFactorIncertitudePercent" key="emissionFactorIncertitudePercent" required />,
  <InputGuesser source="emissionFactorSource" key="emissionFactorSource" />,
  <InputGuesser source="recyclingOrReusePercent" key="recyclingOrReusePercent" />,
  <InputGuesser source="recyclingOrReuseSource" key="recyclingOrReuseSource" />,
  <InputGuesser source="averageWeight" key="averageWeight" />,
  <InputGuesser source="averageWeightIncertitudePercent" key="averageWeightIncertitudePercent" />,

  <Typography key="wasteIncinerationTitle">Incineration</Typography>,
  <InputGuesser source="wasteIncinerationEmissionFactor" key="wasteIncinerationEmissionFactor" />,
  <InputGuesser source="wasteIncinerationCategory" key="wasteIncinerationCategory" />,
  <InputGuesser source="wasteIncinerationEmissionIncertitudePercent" key="wasteIncinerationEmissionIncertitudePercent" />,
  <InputGuesser source="wasteIncinerationSource" key="wasteIncinerationSource" />,

  <Typography key="wasteStorageTitle">Storage / burial</Typography>,
  <InputGuesser source="wasteStorageEmissionFactor" key="wasteStorageEmissionFactor" />,
  <InputGuesser source="wasteStorageCategory" key="wasteStorageCategory" />,
  <InputGuesser source="wasteStorageEmissionIncertitudePercent" key="wasteStorageEmissionIncertitudePercent" />,
  <InputGuesser source="wasteStorageSource" key="wasteStorageSource" />,

  <Typography key="wasteCompostingTitle">Composting</Typography>,
  <InputGuesser source="wasteCompostingEmissionFactor" key="wasteCompostingEmissionFactor" />,
  <InputGuesser source="wasteCompostingCategory" key="wasteCompostingCategory" />,
  <InputGuesser source="wasteCompostingEmissionIncertitudePercent" key="wasteCompostingEmissionIncertitudePercent" />,
  <InputGuesser source="wasteCompostingSource" key="wasteCompostingSource" />,

  <Typography key="wasteAverageTitle">Average</Typography>,
  <InputGuesser source="wasteAverageEmissionFactor" key="wasteAverageEmissionFactor" />,
  <InputGuesser source="wasteAverageCategory" key="wasteAverageCategory" />,
  <InputGuesser source="wasteAverageEmissionIncertitudePercent" key="wasteAverageEmissionIncertitudePercent" />,
  <InputGuesser source="wasteAverageSource" key="wasteAverageSource" />,
];

export const EmissionRuleCreate = (props) => (
  <CreateGuesser {...props} redirect="list">
    {generateInputs().map((Comp) => Comp)}
  </CreateGuesser>
);

export const EmissionRuleEdit = (props) => (
  <EditGuesser {...props} mutationMode="pessimistic" redirect="list">
    {generateInputs().map((Comp) => Comp)}
  </EditGuesser>
);
