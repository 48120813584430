import React, {
  useRef, useState, useEffect, cloneElement,
} from 'react';
import { get, defaults } from 'lodash';
import {
  ExportButton,
  FormDataConsumer,
  NumberInput,
  required as requiredValidate,
  sanitizeListRestProps,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext,
  downloadCSV,
  Loading,
  Pagination, ReferenceInput, AutocompleteInput,
} from 'react-admin';

import AwesomeSlider from 'react-awesome-slider';
import { useDataProvider, useRecordContext } from 'ra-core';
import { ChipField } from 'ra-ui-materialui';
import Chip from '@material-ui/core/Chip';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import ReactJson from 'react-json-view';
import { Typography } from '@material-ui/core';
import { addParametersToUrl, getProp } from '../helpers';
import { apiFetchCSV } from '../apiFetch';
import { RefreshEstimationButton } from './actions';

export const MultilinesInput = (props) => {
  const { required } = props;
  return <TextInput multiline validate={required ? requiredValidate() : null} {...props} />;
};

const getGoogleMapResult = (address) => geocodeByAddress(address)
  .then((results) => results[0]);

const extractFromAddressComponent = (components) => {
  const address = { locality: '', postalCode: '', country: '' };
  components.forEach((comp) => {
    switch (comp.types[0]) {
      case 'locality':
        address.locality = comp.long_name;
        break;
      case 'country':
        address.country = comp.short_name;
        break;
      case 'postal_code':
        address.postalCode = comp.short_name;
        break;
      default:
        break;
    }
  });
  return address;
};

const handleAutocompleteSelect = async (value) => {
  const googleAddress = await getGoogleMapResult(value);
  const { locality, postalCode, country } = extractFromAddressComponent(googleAddress.address_components);
  const address = {
    formatted: googleAddress.formatted_address, locality, postalCode, country,
  };
  return { address, location: { latitude: googleAddress.geometry.location.lat(), longitude: googleAddress.geometry.location.lng() } };
};

export const AddressAutocomplete = (props) => {
  const { record } = props;
  const [autocompleteValue, setValue] = useState(record.address ? record.address.formatted : '');
  const placeAutocompleteRef = useRef();

  return (
    <>
      <FormDataConsumer>
        {({ formData }) => (
          <PlacesAutocomplete
            value={autocompleteValue}
            onChange={(e) => setValue(e)}
            onSelect={async (result) => {
              const place = await handleAutocompleteSelect(result);
              if (props.addressSource) formData[props.addressSource] = place.address;
              if (props.locationSource) formData[props.locationSource] = place.location;
              setValue(place.address.formatted);
              placeAutocompleteRef.current.blur();
            }}
          >
            {({
              getInputProps, suggestions, getSuggestionItemProps, loading,
            }) => (
              <div>
                <TextInput
                  inputRef={placeAutocompleteRef}
                  {...getInputProps()}
                  name={props.source}
                  label="Search address"
                  value={autocompleteValue}
                  autoComplete="without"
                  id={`${props.source}.search`}
                />
                <ul>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const style = suggestion.active
                      ? { backgroundColor: '#eee', cursor: 'pointer' }
                      : { backgroundColor: '#fff', cursor: 'pointer' };
                    return (
                      <li {...getSuggestionItemProps(suggestion, { style })}>
                        <span>{suggestion.description}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </PlacesAutocomplete>
        )}
      </FormDataConsumer>
    </>
  );
};

export const LatLngInput = (props) => {
  const { source } = props;

  return (
    <>
      <NumberInput {...props} name={`${source}.latitude`} label="Latitude" />
      &nbsp;
      <NumberInput {...props} name={`${source}.longitude`} label="Longitude" />
    </>
  );
};

export const AddressInput = (props) => {
  const { source } = props;

  return (
    <>
      <p>You can use the address search field instead of manually updating te following fields</p>
      <TextInput {...props} name={`${source}.formatted`} id={`${source}.formatted`} label="Full formatted address" />
      &nbsp;
      <TextInput {...props} name={`${source}.locality`} id={`${source}.locality`} label="Locality" />
      &nbsp;
      <TextInput {...props} name={`${source}.postalCode`} id={`${source}.postalCode`} label="Postal code" />
      &nbsp;
      <TextInput {...props} name={`${source}.cityCode`} id={`${source}.cityCode`} label="City code" />
      &nbsp;
      <TextInput {...props} name={`${source}.country`} id={`${source}.country`} label="Country" />
    </>
  );
};

const ChoicableSelectInput = (choices, props, overriddenDefaultValue = null) => {
  const { required, defaultValue } = props;
  return <SelectInput validate={required ? requiredValidate() : null} {...props} choices={choices} defaultValue={defaultValue || overriddenDefaultValue} />;
};

export const AdminRuleActionInput = (props) => {
  const types = [
    { id: 'hard_block_ip', name: 'IPs: Block them for all requests' },
    { id: 'soft_block_ip', name: 'IPs: Forbid them to create a post, request a post, send a message, ...' },
    { id: 'hard_block_device_id', name: 'Device IDs: Block them for all requests (caution: the user can change it by reinstalling the app)' },
    { id: 'soft_block_device_id', name: 'Device IDs: Forbid them to create a post, request a post, send a message, ...' },
    { id: 'moderate_string', name: 'Moderation by string: If a new post contains any of those STRINGS, set it to "pending". If an updated post matches, a new system report is created.' },
    { id: 'moderate_regex', name: 'Moderation by regex: If a new post matches any of those REGEX, set it to "pending". If an updated post matches, a new system report is created.' },
  ];

  return ChoicableSelectInput(types, props);
};

export const categoryRenderer = (cat) => (get(cat, 'name') ? `(${get(cat, 'type')} | ${get(cat, 'name')}) ${get(cat, 'translations.fr')}` : '-');

export const CategoryTypeInput = (props) => {
  const types = [
    { id: 'object', name: 'Object' },
    { id: 'service', name: 'Service' },
  ];

  return ChoicableSelectInput(types, props);
};

export const CategoryItemsTypeInput = (props) => {
  const types = [
    { id: 'clothes', name: 'Clothes' },
    { id: 'shoes', name: 'Shoes' },
    { id: 'bra', name: 'Bra (soutien-gorge)' },
    { id: 'fashion_accessory', name: 'Fashion accessory' },
  ];
  const selectProps = {
    ...props,
    allowEmpty: true,
    emptyValue: null,
    emptyText: '_',
  };

  return ChoicableSelectInput(types, selectProps);
};

export const CategorySelectorInput = ({ helperText, ...props }) => (
  <ReferenceInput
    reference="categories"
    label="Category"
    filterToQuery={(search) => ({ name: search })}
    allowEmpty
    {...props}
  >
    <AutocompleteInput optionText={categoryRenderer} helperText={helperText} />
  </ReferenceInput>
);

export const categoryDiscriminantRenderer = (categoryDiscriminant) => {
  if (get(categoryDiscriminant, 'name')) {
    return `${get(categoryDiscriminant, 'name')} (${get(categoryDiscriminant, 'translations.en')} - ${get(categoryDiscriminant, 'translations.fr')})`;
  }

  return '-';
};

export const CategoryDiscriminantSelectorInput = ({ helperText, ...props }) => (
  <ReferenceInput
    reference="category_discriminants"
    label="Discriminant"
    filterToQuery={(search) => ({ name: search })}
    allowEmpty
    {...props}
  >
    <AutocompleteInput optionText={categoryDiscriminantRenderer} helperText={helperText} />
  </ReferenceInput>
);

export const PublicSectorUsersCityCodesSelectorInput = ({ source, ...props }) => (
  <ReferenceInput
    reference="users"
    source={`cityCodesFromPublicSector[${source}]`}
    label="Public sector cities"
    filterToQuery={(search) => ({ name: search })}
    filter={{ managed: true, 'managedData.type': 'public_sector' }}
    allowEmpty
    {...props}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
);

export const EmissionFactorUnitsInput = (props) => {
  const types = [
    { id: 'item', name: 'Item' },
    { id: 'tons', name: 'Tons' },
  ];

  return ChoicableSelectInput(types, props, 'item');
};

export const RolesTypeInput = (props) => {
  const types = [
    { id: 'ROLE_ADMIN', name: 'ROLE ADMIN' },
    { id: 'ROLE_MODERATOR', name: 'ROLE MODERATOR' },
    { id: 'ROLE_CREATE_USER', name: 'ROLE CREATE USER' },
    { id: 'ROLE_ANALYZER', name: 'ROLE ROLE_ANALYZER USER' },
    { id: 'ROLE_GLOBAL_NOTIFIER', name: 'ROLE ROLE_GLOBAL_NOTIFIER USER' },
  ];

  return ChoicableSelectInput(types, props);
};

export const StateInput = (props) => {
  const status = [
    { id: ['published', 'fully_requested', 'finished'], name: 'All' },
    { id: 'published', name: 'Published' },
    { id: 'fully_requested', name: 'Fully Requested' },
    { id: 'finished', name: 'Finished' },
  ];

  return ChoicableSelectInput(status, props, 'All');
};

export const PostTypeInput = (props) => {
  const types = [
    { id: 'offer', name: 'Offer' },
    { id: 'need', name: 'Need' },
  ];

  return ChoicableSelectInput(types, props);
};

export const PostUniverseInput = (props) => {
  const types = [
    { id: 'child', name: 'child' },
    { id: 'man', name: 'man' },
    { id: 'woman', name: 'woman' },
  ];

  return ChoicableSelectInput(types, props);
};

export const PostStateInput = (props) => {
  const types = [
    { id: 'draft', name: 'Draft' },
    { id: 'pending', name: 'Pending' },
    { id: 'published', name: 'Published' },
    { id: 'fully_requested', name: 'Fully requested' },
    { id: 'finished', name: 'Finished' },
    { id: 'deleted', name: 'Deleted' },
    { id: 'moderated', name: 'Moderated' },
  ];

  return ChoicableSelectInput(types, props);
};

export const TranslationsInput = (props) => {
  const { source } = props;

  return (
    <>
      <Typography>Translations</Typography>
      <Typography variant="caption">
        You can automatically translate into missing locales by using the &quot;Save and translate&quot; button of the EDIT page.
        You must add at least the EN or FR translation in order to use this feature.
        If you want to change all translations, you must remove them before asking for a translation.
      </Typography>
      <br />
      <TextInput {...props} name={`${source}.en`} label="EN *" />
      &nbsp;
      <TextInput {...props} name={`${source}.fr`} label="FR *" />
      <br />
      <TextInput {...props} name={`${source}.el`} label="EL" />
      &nbsp;
      <TextInput {...props} name={`${source}.fi`} label="FI" />
      &nbsp;
      <TextInput {...props} name={`${source}.it`} label="IT" />
      &nbsp;
      <TextInput {...props} name={`${source}.pl`} label="PL" />
      &nbsp;
      <TextInput {...props} name={`${source}.pt`} label="PT" />
      &nbsp;
      <TextInput {...props} name={`${source}.ar`} label="AR" />
      &nbsp;
      <TextInput {...props} name={`${source}.ur`} label="UR" />
      &nbsp;
      <TextInput {...props} name={`${source}.fa`} label="FA" />
      <hr />
    </>
  );
};

export const ArrayTextField = (props) => {
  const { record, source } = props;

  return (
    <>
      {(record[source] || {}).map((item, i) => <Chip key={source + i} label={item} />)}
    </>
  );
};

export const TranslationsField = (props) => {
  const { record, source } = props;

  return (
    <>
      {Object.entries(record[source] || {}).map(([locale, trans]) => <Chip key={locale} label={['fr', 'en'].includes(locale) ? `${locale}: ${trans}` : locale} title={trans} />)}
    </>
  );
};

export const ColorField = (props) => {
  const { record, source } = props;
  if (!record[source]) return <></>;

  return <ChipField {...props} style={{ backgroundColor: `#${record[source]}`, color: '#fff' }} />;
};

export const IconField = (props) => {
  const { className, source } = props;
  const record = useRecordContext(props);
  let value = get(record, source);

  if (!value) {
    return null;
  }

  // Add the missing viewBox property if needed
  if (!value.includes('viewBox')) {
    value = value
      .replace(/width=["'](\d+)["']\s+height=["'](\d+)["']/, 'width="$1" height="$2" viewBox="0 0 $1 $2"')
      .replace(/height=["'](\d+)["']\s+width=["'](\d+)["']/, 'width="$2" height="$1" viewBox="0 0 $2 $1"');
  }

  return (
    <Typography
      className={`icon-field ${className || ''}`}
      variant="body2"
      component="span"
    >
      <span dangerouslySetInnerHTML={{ __html: value }} style={{ fill: 'grey' }} />
    </Typography>
  );
};

export const JsonField = ({ record, source, collapsed }) => {
  if (!record) {
    return null;
  }
  const data = getProp(record, source);

  if (!data) {
    return null;
  }

  return <ReactJson src={data} name={source} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} collapsed={collapsed} />;
};

export const EditActionsWithoutDelete = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const slider = (media, buttonSliderActivated = true, className = null) => (
  <AwesomeSlider buttons={buttonSliderActivated}>
    {media.map((image) => (
      <div key={image.id}>
        <img src={image.contentUrl} title={image.contentUrl} alt={image.contentUrl} className={className} />
      </div>
    ))}
  </AwesomeSlider>
);

const displayMediaContentUrl = (media) => (
  <>
    <p>Images</p>
    {media.map((image) => (
      <Typography>
        <a
          href={`#/media_objects/${image.id.replace(/\//g, '%2F')}/show`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {image.contentUrl}
        </a>
      </Typography>
    ))}
  </>
);

let firstMediaObjectRequest = null; // Promise
export const Media = ({
  record, resource = null, className = null, displaySlider = false,
}) => {
  const dataProvider = useDataProvider();
  const [media, setMedia] = useState();

  useEffect(() => {
    (async function loadMedia() {
      if (media) {
        return;
      }
      let recordImages;
      if (resource === 'users') {
        const user = await dataProvider.getOne('user', { id: record['@id'] });
        if (!user.data.images || !user.data.images.length) {
          return;
        }
        recordImages = user.data.images;
      }

      if (!recordImages) {
        if (!record.images || record.images.length === 0) {
          return;
        }

        recordImages = record.images;
      }

      if (firstMediaObjectRequest === null) {
        // Load the media_objects filters list in cache, see the dataProvider.getMany source code and the hasIdSearchFilter function
        firstMediaObjectRequest = dataProvider.getMany('media_objects', { ids: recordImages.slice(0, 1) }).then(() => {
          firstMediaObjectRequest = Promise.resolve();
        });
      }

      await firstMediaObjectRequest;
      const images = await dataProvider.getMany('media_objects', { ids: recordImages });
      if (images && images.data) {
        setMedia(images.data);
      }
    }());
  }, [media]);
  if (!media) {
    return null;
  }

  if (displaySlider) {
    return slider(media, media.length > 1, className);
  }
  return displayMediaContentUrl(media);
};

export const ListActions = (props) => {
  const [exportLoading, setExportLoading] = useState(false);
  const [exportError, setExportError] = useState(false);
  const {
    className,
    filters,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    filterValues,
    showFilter,
    displayedFilters,
    total,
  } = useListContext();

  const exporter = () => {
    setExportLoading(true);
    setExportError(false);
    apiFetchCSV(addParametersToUrl(`/${resource}/export`, filterValues))
      .then((response) => response.text())
      .then((data) => {
        downloadCSV(data, resource);
        setExportLoading(false);
      }).catch(() => {
        setExportLoading(false);
        setExportError(true);
      });
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {exportLoading ? (
        <Loading loadingPrimary="" loadingSecondary="" className="export-loader" />
      ) : (
        <ExportButton
          label="export"
          disabled={total === 0}
          sort={currentSort}
          exporter={() => exporter()}
          filterValues={filterValues}
        />
      ) }
      {exportError && (
        <div className="error-export">Error export</div>
      )}
    </TopToolbar>
  );
};

export const CityPostsListActions = (props) => {
  const {
    className,
    filters,
    ...rest
  } = props;
  const {
    resource,
    filterValues,
    showFilter,
    displayedFilters,
  } = useListContext();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <RefreshEstimationButton />
    </TopToolbar>
  );
};

export const CustomPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const transformToTree = (categories) => {
  const nodes = {};
  return categories.filter((cat) => {
    const id = cat['@id'];
    const parentId = cat.parent ? cat.parent['@id'] : undefined;
    nodes[id] = defaults(cat, nodes[id], { children: [] });
    parentId && (nodes[parentId] = (nodes[parentId] || { children: [] })).children.push(cat);

    return !parentId;
  });
};

const getCategoryParents = (categories, category, parents = []) => {
  parents.push(category);

  if (!category.parent) {
    return parents;
  }
  const parentCategory = categories.find((cat) => cat['@id'] === category.parent['@id']);
  if (!parentCategory) {
    return parents;
  }

  return getCategoryParents(categories, parentCategory, parents);
};

export const getCategoryDiscriminants = (categories, category) => {
  if (!category.parent) {
    return category.discriminants || [];
  }

  const parentCategory = categories.find((cat) => cat['@id'] === category.parent['@id']);
  if (category.overrideParentDiscriminants || !parentCategory) {
    return category.discriminants || [];
  }

  return [...category.discriminants, ...getCategoryDiscriminants(categories, parentCategory)];
};

export const getCategoryChildren = (categories, category, children = []) => {
  if (!category.children) {
    return children;
  }
  category.children.forEach((cat) => {
    children.push({
      value: cat['@id'],
      label: getCategoryParents(categories, cat).map((categoryResult) => categoryResult.translations.fr).reverse().join(' > '),
      type: cat.type,
    });
    getCategoryChildren(categories, cat, children);
  });

  return children;
};
