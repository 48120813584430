import { FieldGuesser, ListGuesser } from '@api-platform/admin';
import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from 'react-admin';
import React from 'react';
import { CustomPagination } from '../fields/resources';
import { ApplyTransitionButton } from '../fields/actions';

export const PostRequestList = (props) => (
  <ListGuesser {...props} sort={{ field: 'createdAt', order: 'DESC' }} pagination={<CustomPagination />}>
    <ReferenceField source="post" reference="posts" link="show">
      <TextField source="title" />
    </ReferenceField>
    <ReferenceField source="user" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="state" addLabel />
    <FieldGuesser source="createdAt" addLabel />
  </ListGuesser>
);

const PostRequestShowActions = ({ data }) => (
  <TopToolbar>
    {data && (data.state === 'waiting' || data.state === 'accepted') && (
      <ApplyTransitionButton record={data} transition="cancel" label="Cancel as the requester" />
    )}
    {data && (data.state === 'waiting' || data.state === 'accepted') && (
      <ApplyTransitionButton record={data} transition="reject" label="Reject as the post creator" />
    )}
  </TopToolbar>
);

export const PostRequestShow = (props) => (
  <Show actions={<PostRequestShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField source="post" reference="posts" link="show">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="user" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source="state" addLabel />
      <FieldGuesser source="createdAt" addLabel />
      <ArrayField source="stateHistory">
        <Datagrid>
          <TextField source="from" label="From" />
          <TextField source="to" label="To" />
          <DateField source="date" showTime />
        </Datagrid>
      </ArrayField>

      <ReferenceManyField
        label="Reviews"
        reference="user_reviews"
        target="reviewedPostRequest"
      >
        <Datagrid>
          <TextField source="type" />
          <TextField source="comment" />
          <ReferenceField source="reviewedUser" reference="users" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="createdBy" reference="users" link="show">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" showTime />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);
