import React from 'react';
import { Layout, MenuItemLink } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SecurityIcon from '@material-ui/icons/Security';
import EventIcon from '@material-ui/icons/Event';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import BusinessIcon from '@material-ui/icons/Business';
import LocationCity from '@material-ui/icons/LocationCity';
import AppBar from './AppBar';

export const IndigoMenu = () => (
  <>
    <br />
    <MenuItemLink to="/users" primaryText="Users" leftIcon={<PeopleIcon />} />
    <MenuItemLink to="/posts?filter={}" primaryText="Posts" leftIcon={<ListAltIcon />} />
    <MenuItemLink to='/posts?filter={"state":"pending"}' primaryText="Pending posts" leftIcon={<HourglassEmptyIcon />} />
    <MenuItemLink to="/city_posts" primaryText="City Posts" leftIcon={<BookIcon />} />
    <MenuItemLink to="/reports" primaryText="Reports" leftIcon={<ReportProblemIcon />} />
    <MenuItemLink to="/admin_rules" primaryText="Admin rules" leftIcon={<SecurityIcon />} />
    <MenuItemLink to="/customers" primaryText="Customers" leftIcon={<SecurityIcon />} />

    <hr />
    <MenuItemLink to="/user_logs" primaryText="User logs" leftIcon={<EventIcon />} />

    <hr />
    <MenuItemLink to="/emission_rules" primaryText="Emission rules" leftIcon={<BusinessIcon />} />
    <MenuItemLink to="/categories" primaryText="Categories" leftIcon={<ListIcon />} />
    <MenuItemLink to="/category_discriminants" primaryText="Category Discriminants" leftIcon={<ListIcon />} />
    <MenuItemLink to="/category_thematics" primaryText="Category Thematics" leftIcon={<ListIcon />} />

    <hr />
    <MenuItemLink to="/localities" primaryText="Localities" />
    <MenuItemLink to="/user_reviews" primaryText="User reviews" />
    <MenuItemLink to="/post_requests" primaryText="Post requests" />
    <MenuItemLink to="/media_objects" primaryText="Media objects" />
  </>
);

export const IndigoLayout = (props) => <Layout {...props} menu={IndigoMenu} appBar={AppBar} />;

export const CircularMenu = () => (
  <>
    <br />
    <MenuItemLink to="/customers" primaryText="Customers" leftIcon={<LocationCity />} />
  </>
);

export const CircularLayout = (props) => <Layout {...props} menu={CircularMenu} appBar={AppBar} />;
