import {
  CreateGuesser, FieldGuesser, InputGuesser, ListGuesser,
} from '@api-platform/admin';
import {
  Edit,
  ReferenceField,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Filter,
  NullableBooleanInput,
  BooleanInput, Pagination, Datagrid, ShowButton, ReferenceManyField,
} from 'react-admin';
import React from 'react';
import {
  categoryDiscriminantRenderer,
  CategoryItemsTypeInput, CategorySelectorInput,
  CategoryTypeInput,
  CustomPagination,
  IconField,
  MultilinesInput,
  TranslationsField,
  TranslationsInput,
} from '../fields/resources';
import { EditActionsWithTranslate } from '../fields/actions';

const CategoriesFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <CategoryTypeInput source="type" alwaysOn />
    <TextInput source="parent.name" label="Parent name" alwaysOn />
    <CategoryItemsTypeInput source="itemsType" alwaysOn />
    <BooleanInput label="With an icon" source="exists[icon]" />
    <NullableBooleanInput label="With a min weight" source="exists[itemMinWeight]" />
    <NullableBooleanInput label="With a max weight" source="exists[itemMaxWeight]" />
    <NullableBooleanInput label="With a default weight" source="exists[itemDefaultWeight]" />
    <NullableBooleanInput label="With a default price" source="exists[itemDefaultPrice]" />
    <NullableBooleanInput source="overrideParentDiscriminants" />
  </Filter>
);

export const CategoriesList = (props) => (
  <ListGuesser {...props} sort={{ field: 'order', order: 'ASC' }} filters={<CategoriesFilter />} pagination={<CustomPagination />}>
    <FieldGuesser source="name" />
    <ReferenceField source="parent" reference="categories" link="edit" sortable={false}>
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="type" sortable={false} />
    <IconField source="icon" sortable={false} />
    <TranslationsField source="translations" sortable={false} />
    <FieldGuesser source="order" />
    <FieldGuesser source="itemsType" />
    <FieldGuesser source="itemMinWeight" />
    <FieldGuesser source="itemMaxWeight" />
    <FieldGuesser source="itemDefaultWeight" />
    <FieldGuesser source="itemDefaultPrice" />
  </ListGuesser>
);

export const CategoryCreate = (props) => (
  <CreateGuesser {...props} redirect="edit">
    <InputGuesser source="name" />
    <CategorySelectorInput source="parent" label="Parent" />
    <CategoryTypeInput source="type" required />
    <TranslationsInput source="translations" />
    <MultilinesInput source="icon" helperText="The icon must be a SVG string, format: &lt;svg&gt;...&lt;/svg&gt;" />
    <InputGuesser source="order" helperText="The order of the category, from small to big, null are smallest" />
    <CategoryItemsTypeInput source="itemsType" />
    <InputGuesser source="itemMinWeight" />
    <InputGuesser source="itemMaxWeight" />
    <InputGuesser source="itemDefaultWeight" />
    <InputGuesser source="itemDefaultPrice" />
    <ReferenceArrayInput label="Discriminants" reference="category_discriminants" source="discriminants">
      <AutocompleteArrayInput optionText={categoryDiscriminantRenderer} />
    </ReferenceArrayInput>
    <InputGuesser source="overrideParentDiscriminants" helperText="If true, only discriminants specified in this category are proposed to the user. If false, we propose both parents' and child's discriminants." />
  </CreateGuesser>
);

export const CategoryEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect="list">
    <SimpleForm toolbar={<EditActionsWithTranslate />}>
      <TextInput source="name" disabled />
      <CategorySelectorInput source="parent" label="Parent" />
      <CategoryTypeInput source="type" required />
      <TranslationsInput source="translations" />
      <MultilinesInput source="icon" helperText="The icon must be a SVG string, format: &lt;svg&gt;...&lt;/svg&gt;" />
      <InputGuesser source="order" helperText="The order of the category, from small to big, null are smallest" />
      <CategoryItemsTypeInput source="itemsType" />
      <InputGuesser source="itemMinWeight" />
      <InputGuesser source="itemMaxWeight" />
      <InputGuesser source="itemDefaultWeight" />
      <InputGuesser source="itemDefaultPrice" />
      <ReferenceArrayInput label="Discriminants" reference="category_discriminants" source="discriminants">
        <AutocompleteArrayInput optionText={categoryDiscriminantRenderer} />
      </ReferenceArrayInput>
      <InputGuesser source="overrideParentDiscriminants" helperText="If true, only discriminants specified in this category are proposed to the user. If false, we propose both parents' and child's discriminants." />

      <ReferenceManyField
        label="Emission rules"
        reference="emission_rules"
        target="itemCategory"
        perPage={10}
        filter={{ itemsPerPage: 10 }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <FieldGuesser source="itemCategoryDiscriminant" addLabel sortable={false} />
          <FieldGuesser source="emissionFactor" addLabel sortable={false} />
          <FieldGuesser source="createdAt" addLabel showTime sortable={false} />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
);
