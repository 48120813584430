import {
  FieldGuesser, InputGuesser, ListGuesser,
} from '@api-platform/admin';
import {
  ArrayField, Datagrid, DateField, Edit, SimpleForm, TextField, Show, SimpleShowLayout,
  TextInput, TopToolbar, EditButton, ShowButton, NullableBooleanInput,
  ReferenceManyField, ReferenceField, Pagination, Filter, DateTimeInput, ReferenceArrayInput, AutocompleteArrayInput, NumberInput,
} from 'react-admin';
import { Button } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import React from 'react';
import {
  AddressAutocomplete,
  AddressInput,
  CategoryTypeInput,
  EditActionsWithoutDelete,
  JsonField,
  LatLngInput,
  MultilinesInput,
  PostTypeInput,
  Media,
  ListActions,
  CustomPagination,
  PostStateInput,
  CategorySelectorInput,
  PostUniverseInput,
  categoryDiscriminantRenderer,
  PublicSectorUsersCityCodesSelectorInput,
} from '../fields/resources';
import { ApplyTransitionButton, PostAnalyzer, PostEstimate } from '../fields/actions';
import { clientUrl } from '../environment';

const PostsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Title (attention aux accents)" source="title" alwaysOn />
    <PostTypeInput source="type" alwaysOn />
    <PostStateInput source="state" alwaysOn />
    <PublicSectorUsersCityCodesSelectorInput source="address.cityCode" alwaysOn />
    <CategoryTypeInput source="categoryType" />
    <CategorySelectorInput source="category" />
    <TextInput label="created before" source="createdAt.before" placeholder="ex: 30-03-2021" />
    <TextInput label="created after" source="createdAt.after" placeholder="ex: 30-03-2021" />
    <TextInput source="address.postalCode" label="Postal code" />
    <NumberInput source="metadata.calculatedWeight[gte]" label="Min weight" />
    <NumberInput source="metadata.calculatedPrice[gte]" label="Min price" />
    <NullableBooleanInput source="exists[metadata.calculatedWeight]" label="With a calculated weight" />
    <NullableBooleanInput source="exists[metadata.calculatedPrice]" label="With a calculated price" />
  </Filter>
);

export const PostsList = (props) => (
  <ListGuesser {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={<PostsFilter />} actions={<ListActions />} pagination={<CustomPagination />}>
    <FieldGuesser source="type" />
    <FieldGuesser source="state" />
    <FieldGuesser source="categoryType" />
    <FieldGuesser source="category" />
    <FieldGuesser source="title" />
    <ReferenceField source="createdBy" reference="users" link="show">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="createdAt" />
    <FieldGuesser source="updatedAt" />
  </ListGuesser>
);

const PostShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />

    {data && (data.state === 'draft' || data.state === 'moderated' || data.state === 'pending') && (
      <ApplyTransitionButton record={data} transition="publish" label="Publish" />
    )}
    {data && data.state === 'published' && (
      <ApplyTransitionButton record={data} transition="unpublish" label="Unpublish" />
    )}
    {data && ['published', 'fully_requested'].includes(data.state) && (
      <ApplyTransitionButton record={data} transition="finish" label="Finish" />
    )}
    {data && ['draft', 'pending', 'published', 'fully_requested'].includes(data.state) && (
    <>
      <ApplyTransitionButton record={data} transition="delete" label="Delete" />
      <ApplyTransitionButton record={data} transition="moderate" label="Moderate" alertMessage="Moderate post and send a notification to the creator, the reporters, and all people who asked it" />
    </>
    )}
    {data && (
    <Button href={`${clientUrl}${data.id}`} className="button-size" color="primary" target="_blank" rel="noopener noreferrer">View in app</Button>
    )}
    {data && data.categoryType === 'object' && (
      <Button
        href={`/#/city_posts?filter={"state":["${(data.state)}"],"id":"${data.id.replace('/posts/', '')}"}`}
        className="button-size"
        color="primary"
        rel="noopener noreferrer"
      >
        <CreateIcon /> Edit city metadata
      </Button>
    )}
  </TopToolbar>
);

export const PostShow = (props) => (
  <Show actions={<PostShowActions />} {...props}>
    <SimpleShowLayout>
      <Media displaySlider className="media-big" />
      <FieldGuesser source="title" addLabel />
      <FieldGuesser source="description" addLabel />

      <ReferenceField source="createdBy" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime addLabel />
      <DateField source="updatedAt" showTime addLabel />
      <DateField source="tooOldNotificationSentAt" showTime addLabel />
      <FieldGuesser source="type" addLabel />
      <FieldGuesser source="state" addLabel />
      <FieldGuesser source="categoryType" addLabel />
      <FieldGuesser source="category" addLabel />
      <FieldGuesser source="discriminants" addLabel />
      <FieldGuesser source="requestsLimit" addLabel />
      <FieldGuesser source="universe" addLabel />
      <FieldGuesser source="size" addLabel />
      <FieldGuesser source="quantity" addLabel />
      <FieldGuesser source="estimatedWeight" addLabel />
      <JsonField source="address" label="Address" addLabel />
      <JsonField source="location" label="Location" addLabel />
      <JsonField source="publicLocation" label="Public & fake location" addLabel />
      <FieldGuesser source="discriminants" addLabel />
      <ArrayField source="stateHistory">
        <Datagrid>
          <TextField source="from" label="From" />
          <TextField source="to" label="To" />
          <TextField source="reason" label="Reason" />
          <DateField source="date" showTime />
        </Datagrid>
      </ArrayField>

      <Media />

      <ReferenceManyField
        label="Requests"
        reference="post_requests"
        target="post"
        perPage={10}
        filter={{ itemsPerPage: 10 }}
        pagination={<Pagination />}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >

        <Datagrid>
          <TextField source="state" />
          <ReferenceField source="user" reference="users" link="show">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" showTime />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>

      <ReferenceManyField
        label="Reports received"
        reference="reports"
        target="reportedPost"
        perPage={5}
        filter={{ itemsPerPage: 5 }}
        pagination={<Pagination />}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <Datagrid>
          <FieldGuesser source="reason" addLabel />
          <FieldGuesser source="creatorComment" addLabel />
          <FieldGuesser source="createdAt" showTime addLabel />
          <ReferenceField source="createdBy" reference="users" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>

      <JsonField source="metadata" label="Metadata" addLabel />

      <PostEstimate />
      <hr />
      <PostAnalyzer />
    </SimpleShowLayout>
  </Show>
);

export const PostEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm toolbar={<EditActionsWithoutDelete />} redirect="show">
      <PostTypeInput source="type" required />
      <CategoryTypeInput source="categoryType" required />
      <CategorySelectorInput source="category" addLabel />
      <TextInput source="title" />
      <MultilinesInput source="description" required />
      <InputGuesser source="requestsLimit" />
      <AddressAutocomplete source="address.formatted" addressSource="address" locationSource="location" />
      <AddressInput source="address" />
      <LatLngInput source="location" />
      <PostUniverseInput source="universe" />
      <InputGuesser source="size" />
      <ReferenceArrayInput label="Discriminants" reference="category_discriminants" source="discriminants">
        <AutocompleteArrayInput optionText={categoryDiscriminantRenderer} />
      </ReferenceArrayInput>
      <DateTimeInput source="updatedAt" />
    </SimpleForm>
  </Edit>
);
