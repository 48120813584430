import { get } from 'lodash';
import { fetchHydra as baseFetchHydra, useIntrospection } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { Redirect, Route } from 'react-router';
import React from 'react';
import { apiEntrypoint } from './environment';

const getHeaders = (options) => {
  const headers = { ...(options ? options.headers || {} : {}) };

  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  return headers;
};

export const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: getHeaders,
});

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem('token')) {
    introspect();
    return <></>;
  }
  return <Redirect to="/login" />;
};

export const apiFetch = (uri, options = {}) => {
  if (typeof options.body === 'object') {
    options.body = JSON.stringify(options.body);
    options.headers = { 'Content-Type': 'application/json' };
  }

  return fetchHydra(apiEntrypoint + uri, options);
};

export const apiFetchJson = async (uri, options = {}) => {
  options.headers = { Accept: options.accept ? options.accept : 'application/json' };
  const request = new Request(apiEntrypoint + uri, {
    method: options.method ? options.method : 'GET',
    headers: new Headers(getHeaders(options)),
  });

  const response = await fetch(request);
  if (!response.headers.get('content-type')) {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.ok;
  }

  const json = await response.json();
  if (!response.ok) {
    const errorParts = [response.statusText, json.title, json.detail, json['hydra:title'], json['hydra:description']].filter((val) => !!val);
    throw new Error(errorParts.join(' - '));
  }

  return json;
};

export const apiFetchCSV = (uri, options = {}) => {
  options.headers = { Accept: 'text/csv' };
  const request = new Request(apiEntrypoint + uri, {
    method: 'GET',
    headers: new Headers(getHeaders(options)),
  });

  return fetch(request);
};

export const apiDocumentationParser = async (entrypoint) => {
  try {
    // headers must be a function, as  auth token is not already defined when the hydra documentation is parsed
    const { api } = await parseHydraDocumentation(entrypoint, { headers: getHeaders });
    return { api };
  } catch (result) {
    if (result.status === 401) {
      // Prevent infinite loop if the token is expired
      localStorage.removeItem('token');

      return {
        api: result.api,
        customRoutes: [
          <Route path="/" component={RedirectToLogin} />,
        ],
      };
    }

    throw result;
  }
};

export const parseHydraCollection = (data) => {
  const items = data['hydra:member'];
  const totalItems = data['hydra:totalItems'];
  const nextPage = get(data, ['hydra:view', 'hydra:next']);
  const previousPage = get(data, ['hydra:view', 'hydra:previous']);

  return {
    items,
    totalItems,
    nextPage,
    previousPage,
  };
};

export const fetchAllItems = (url, fetchOptions, previousItems = []) => apiFetch(url, fetchOptions)
  .then((response) => response.json)
  .then((data) => {
    const collection = parseHydraCollection(data);
    if (collection.nextPage) {
      return fetchAllItems(collection.nextPage, fetchOptions, previousItems.concat(collection.items));
    }

    return previousItems.concat(collection.items);
  });
