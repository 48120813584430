import {
  CreateGuesser, FieldGuesser, InputGuesser, ListGuesser,
} from '@api-platform/admin';
import React from 'react';
import {
  AutocompleteArrayInput,
  Edit, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectInput, SimpleForm, TextField,
} from 'react-admin';
import {
  categoryRenderer,
  ColorField, CustomPagination, TranslationsField, TranslationsInput,
} from '../fields/resources';
import { EditActionsWithTranslate } from '../fields/actions';

export const CategoryThematicsList = (props) => (
  <ListGuesser {...props} sort={{ field: 'order', order: 'ASC' }} pagination={<CustomPagination />}>
    <FieldGuesser source="name" />
    <ReferenceField source="parent" reference="category_thematics" link="edit">
      <TextField source="name" />
    </ReferenceField>
    <ColorField source="color" />
    <TranslationsField source="translations" />
    <FieldGuesser source="order" />
  </ListGuesser>
);

export const CategoryThematicCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <ReferenceInput source="parent" reference="category_thematics" allowEmpty>
      <SelectInput source="name" />
    </ReferenceInput>
    <InputGuesser source="color" />
    <TranslationsInput source="translations" />
    <ReferenceArrayInput label="Categories" reference="categories" source="categories">
      <AutocompleteArrayInput optionText={categoryRenderer} />
    </ReferenceArrayInput>
    <InputGuesser source="order" helperText="The order of the category, from small to big, null are smallest" />
  </CreateGuesser>
);

export const CategoryThematicEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect="list">
    <SimpleForm toolbar={<EditActionsWithTranslate />}>
      <ReferenceInput source="parent" reference="category_thematics" allowEmpty>
        <SelectInput source="name" />
      </ReferenceInput>
      <InputGuesser source="color" />
      <TranslationsInput source="translations" />
      <ReferenceArrayInput label="Categories" reference="categories" source="categories">
        <AutocompleteArrayInput optionText={categoryRenderer} />
      </ReferenceArrayInput>
      <InputGuesser source="order" helperText="The order of the category, from small to big, null are smallest" />
    </SimpleForm>
  </Edit>
);
